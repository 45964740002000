import React from 'react';
import './benefits.css';
import CheckCircle from '../../assets/CheckCircle1.svg';
import { API } from '../../types/api';

const BenefitCard = ({
  title,
  content,
}: {
  title: string;
  content?: string;
}) => {
  return (
    <div className="">
      <div className="flex mb-[10px]">
        {/* <h6 className="mb-2 font-large font-bold tracking-tight ">{title}</h6> */}
        <img src={CheckCircle} alt="" className="mr-3 h-[18px] mt-[4px]" />
        <p className="font-normal text-white">
          {title}
          {content && `: ${content}`}
        </p>
      </div>
    </div>
  );
};

const Benefits = ({ webinar }: { webinar: API.Responses.WebinarDetails }) => {
  const { benefits_title, benefits } = webinar.page;

  return (
    <div className="lg:px-[150px] lg:py-[100px] w-full text-black sm:px-[3rem] p-4 !py-16 bg-[#FF6F31] BricolageFont centerPage">
      <div className="max-w-[1200px]">
        <div className="pb-[24px]">
          <h3 className="sm:text-[44px] text-[32px] font-semibold tracking-tight text-white">
            {benefits_title || 'What will you learn from this workshop?'}
          </h3>
        </div>
        <div className="sm:grid sm:grid-cols-2 gap-x-6 gap-y-4 text-left mt-10">
          {benefits.map((benefit) => (
            <BenefitCard
              title={benefit.value.title}
              content={benefit.value.body}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benefits;
