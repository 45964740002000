import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
// import { Success } from './pages/Success'

import { OrangeTemplate } from './pages/OrangeTemplate';
import { YellowTemplate } from './pages/YellowTemplate';
import { Yellow } from './pages/Yellow';
import { PurpleTemplate } from './pages/PurpleTemplate';
import { WebinarLoader } from './pages/WebinarLoader';
import { Success } from './pages/Success';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/workshop/:slug" element={<WebinarLoader />} />
        {/* <Route path="/template/orange" element={<OrangeTemplate />} /> */}
        {/* <Route path="/template/yellow" element={<YellowTemplate />} /> */}
        {/* <Route path="/yellow" element={<Yellow />} /> */}
        {/* <Route path="/template/purple" element={<PurpleTemplate />} /> */}
        <Route path="/success" element={<Success />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
