import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../components/Common/Footer';
import { Helmet } from 'react-helmet';
import { YellowTemplate } from '../components/Yellow/YellowTemplate';
import { API } from '../types/api';
import { RegisterModal } from '../components/Modals/Register';

export const Yellow = ({
  page,
}: {
  page: API.Responses.WebinarDetails;
}) => {
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);

  const onRegister = () => {
    setRegisterModalOpen(true);
  };

//   const { name } = page.page;

  return (
    <div className="w-full text-white bg-[#1F1F1F]">
      <Helmet>
        <title>{page.page.name}</title>
        <meta name="description" content={page.page.name} />
      </Helmet>
        <YellowTemplate page={page} onRegister={onRegister} />
      
      <Footer bg='bg-primary' />
      <RegisterModal
        show={registerModalOpen}
        setShow={setRegisterModalOpen}
        webinar={page}
      />
    </div>
  );
};
