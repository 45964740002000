import React, { useState, useEffect, useRef } from 'react';
import image from '../../assets/image.webp';
import './Features.css';
import { Link } from 'react-router-dom';
import { API } from '../../types/api';
import moment from 'moment-timezone';

// const TestimonialCard = ({
//     // title,
//     content,
// }: {
//     // title: string;
//     content: string;
// }) => {
//     return (
//         <div className="border border-gray-200 rounded-lg shadow mb-3">
//             <div className="p-5">
//                 {/* <h6 className="mb-2 font-large font-bold tracking-tight ">{title}</h6> */}
//                 <p className="mb-3 font-normal text-sm mt-4">{content}</p>
//             </div>
//         </div>
//     );
// };

const TimeRemains = ({
  webinar,
  onRegister,
}: {
  webinar: API.Responses.WebinarDetails;
  onRegister: Function;
}) => {
  const [showCoundown, setShowCountdown] = useState(true);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const endTime = moment(webinar.page.starts_at).toDate().getTime();
  // const [timeLeft, setTimeLeft] = useState((endTime - now)/1000);

  let interval: any = useRef();

  const startCoundown = () => {
    interval = setInterval(() => {
      const now = new Date().getTime();
      const timeLeft = (endTime - now) / 1000;
      setSeconds(Math.floor(timeLeft % 60));
      setMinutes(Math.floor(timeLeft / 60) % 60);
      setHours(Math.floor(timeLeft / (60 * 60)) % 24);
      setDays(Math.floor(timeLeft / (60 * 60 * 24)));
      // console.log('now', now);
      if (timeLeft <= 0) {
        clearInterval(interval.current);
        setSeconds(0);
        setMinutes(0);
        setHours(0);
        setDays(0);
        setShowCountdown(false);
      }
    }, 1000);
  };

  useEffect(() => {
    startCoundown();
    return () => {
      clearInterval(interval.current);
    };
  });

  return (
    <div
      className={`lg:px-[150px] lg:py-[100px] w-full text-white sm:px-[3rem] p-4 !py-16 bg-black BricolageFont centerPage ${
        showCoundown ? '' : 'hidden'
      }`}
    >
      <div className="max-w-[1440px] sm:items-center flex flex-col">
        <div className="pb-[32px] text-center">
          {/* <label className="text-[18px] text-white">FEATURES</label> */}
          <h3 className="sm:text-[44px] text-[28px] pb-[16px] font-semibold tracking-tight">
            Time is running out, Book your spot fast
          </h3>
        </div>
        <div className="w-full sm:grid sm:grid-cols-4 grid grid-cols-4 sm:gap-8 gap-4 text-left">
          <div className="timeCard sm:mb-0 mb-3">
            <div className="contents">
              <h3 className="sm:text-[80px] text-[44px] font-large font-bold tracking-tight ">
                {days}
              </h3>
              <p className="font-normal text-sm">Days</p>
            </div>
          </div>
          <div className="timeCard sm:mb-0 mb-3">
            <div className="contents">
              <h3 className="sm:text-[80px] text-[44px] font-large font-bold tracking-tight ">
                {hours}
              </h3>
              <p className="font-normal text-sm">Hours</p>
            </div>
          </div>
          <div className="timeCard sm:mb-0 mb-3">
            <div className="contents">
              <h3 className="sm:text-[80px] text-[44px] font-large font-bold tracking-tight ">
                {minutes}
              </h3>
              <p className="font-normal text-sm">Minutes</p>
            </div>
          </div>
          <div className="timeCard sm:mb-0 mb-3">
            <div className="contents">
              <h3 className="sm:text-[80px] text-[44px] font-large font-bold tracking-tight ">
                {seconds}
              </h3>
              <p className="font-normal text-sm">Seconds</p>
            </div>
          </div>
        </div>
        <div className="pt-[32px] text-center">
          <div onClick={() => onRegister()} className="sm:w-fit grid w-full">
            <button
              type="button"
              className="text-white bg-[#FF6F31] hover:bg-white hover:text-black focus:ring-4 focus:ring-blue-300 text-[18px] font-semibold leading-7 rounded-lg px-5 py-2.5 mb-2"
            >
              Book your Slot for ₹{webinar.page.registration_amount}
            </button>
          </div>
          {/* <p className="text-[14px] tracking-tight">
                        <span className='opacity-[0.6]'>Reserve before </span><span className='text-[#FFF] text-[14px] font-semibold'>14 October</span> <span className='opacity-[0.6]'>to get a discount of </span><span className='text-[#FFF] text-[14px] font-semibold'>12%</span>
                    </p> */}
        </div>
      </div>
    </div>
  );
};

export default TimeRemains;
