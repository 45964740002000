import { API } from '../../types/api';
import { backendClient } from './http-client';
import { AxiosResponse } from 'axios';
import { RequestGenerators } from './request-generator';
import { handleAxiosError } from '../error';

class Webinar {
  static async getWebinarDetails(slug: string) {
    try {
      const config = RequestGenerators.getWebinarDetails(slug);
      const _response: AxiosResponse<API.Responses.WebinarDetails> =
        await backendClient.request(config);

      return _response.data;
    } catch (error) {
      return handleAxiosError(error);
    }
  }

  static async register(
    webinarId: number,
    fields: {
      name: string;
      phone: string;
      email: string;
      city: string;
      state: string;
      age: number;
      type?: 'employed' | 'student';
      organization?: string;
      jobProfile?: string;
      designation?: string;
      institute?: string;
      course?: string;
    }
  ) {
    try {
      const requestConfig = RequestGenerators.registerWebinar(
        webinarId,
        fields
      );

      const _response: AxiosResponse<API.Responses.RegisterWebinar> =
        await backendClient.request(requestConfig);

      return _response.data;
    } catch (error) {
      console.log({ error });
      throw error;
    }
  }

  static async verify(fields: {
    orderId: string;
    paymentId: string;
    signature: string;
  }) {
    try {
      const requestConfig = RequestGenerators.verifyPayment(fields);

      const _response: AxiosResponse<API.Responses.RegisterWebinar> =
        await backendClient.request(requestConfig);

      return _response.data;
    } catch (error) {
      console.log({ error });
      throw error;
    }
  }

  static async registerV2(
    webinarId: number,
    fields: {
      name: string;
      phone: string;
      email: string;
      state: string;
      couponCode?: string;
    }
  ) {
    try {
      const requestConfig = RequestGenerators.registerWebinarV2(
        webinarId,
        fields
      );

      const _response: AxiosResponse<API.Responses.RegisterWebinar> =
        await backendClient.request(requestConfig);

      return _response.data;
    } catch (error) {
      console.log({ error });
      throw error;
    }
  }

  static async verifyV2(fields: {
    orderId: string;
    paymentId: string;
    signature: string;
  }) {
    try {
      const requestConfig = RequestGenerators.verifyPaymentV2(fields);

      const _response: AxiosResponse<API.Responses.RegisterWebinar> =
        await backendClient.request(requestConfig);

      return _response.data;
    } catch (error) {
      console.log({ error });
      throw error;
    }
  }

  static async checkCouponCode(webinarId: number, couponCode: string) {
    try {
      const requestConfig = RequestGenerators.checkCouponCode(
        webinarId,
        couponCode
      );

      const _response: AxiosResponse<API.Responses.CheckCouponCode> =
        await backendClient.request(requestConfig);

      return _response.data;
    } catch (error) {
      return handleAxiosError(error);
    }
  }
}

export default {
  Webinar,
};
