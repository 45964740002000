import React from 'react'
import { API } from '../../types/api'

import checkbox from '../../assets/checkbox.png'
import bg from '../../assets/reason-bg.svg'

export const Reason = (
    {
        title,
        points
    } :
    {
        title: string,
        points: API.Responses.WebinarDetails['page']['points_covered']
    }
) => {
  return (
    <div className='w-full flex flex-col bg-[#2B2B2B] py-24 px-5 md:px-32 items-start relative'>
        <div className='flex flex-col'>
            <p className='uppercase font-instrument'>Reason</p>

            <p className='uppercase font-bebas mt-2 text-4xl'>{title}</p>
        </div>

        <div className='mt-10 w-full flex flex-wrap'>
            {
                points.map((i) => {
                    return (
                        <div className='flex items-center w-full md:w-1/2 md:pr-10 mb-8'>
                            <img src={checkbox} />
                            <p className='ml-5 font-instrument md:text-lg font-medium'>{i.value.title}</p>
                        </div>

                    )
                })
            }
                        
        </div>

        <img className='absolute top-0 right-0' src={bg} />
    </div>
  )
}
