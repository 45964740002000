import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './styles.css';

import dots from '../../assets/purple/dots.png';
import stars from '../../assets/purple/stars.png';
import youtube from '../../assets/youtube.svg';
import linkedin from '../../assets/linkedin.svg';
import instagram from '../../assets/instagram.svg';
import vr from '../../assets/purple/vr.png';
import tick from '../../assets/purple/tick.png';
import purple_rect from '../../assets/purple/purple-rect.png';
import avatar from '../../assets/purple/avatar.png';
import carol from '../../assets/purple/carol-img1.png';
import carol2 from '../../assets/purple/carol-img2.png';
import press1 from '../../assets/purple/Press logo1.png';
import press2 from '../../assets/purple/Press logo2.png';
import press3 from '../../assets/purple/Press logo3.png';
import press4 from '../../assets/purple/Press logo4.png';
import down_arrow from '../../assets/purple/down-arrow.png';
import { API } from '../../types/api';
import moment from 'moment-timezone';

const FAQBox = ({ title, content }: { title: string; content: string }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="collapse-div">
      <a onClick={() => setShow(!show)} className="collapsible ">
        <p className="!text-[20px]">{title}</p>
        <img src={down_arrow} alt="" />
      </a>
      {show && (
        <div className="content">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export const Purple = ({
  webinar,
  onRegister,
}: {
  webinar: API.Responses.WebinarDetails;
  onRegister: Function;
}) => {
  const {
    host_name,
    host_bio,
    host_designation,
    before_discount_amount,
    benefits,
    benefits_title,
    closes_at,
    ends_at,
    excerpt,
    faq_title,
    faqs,
    host_image_url,
    keywords,
    name,
    pk,
    points_covered,
    points_covered_title,
    registration_amount,
    starts_at,
    statistics,
    testimonials,
    testimonials_title,
  } = webinar.page;

  const [showCoundown, setShowCountdown] = useState(true);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const endTime = moment(starts_at).toDate().getTime();

  let interval: any = useRef();

  const startCoundown = () => {
    interval = setInterval(() => {
      const now = new Date().getTime();
      const timeLeft = (endTime - now) / 1000;
      setSeconds(Math.floor(timeLeft % 60));
      setMinutes(Math.floor(timeLeft / 60) % 60);
      setHours(Math.floor(timeLeft / (60 * 60)) % 24);
      setDays(Math.floor(timeLeft / (60 * 60 * 24)));
      // console.log('now', now);
      if (timeLeft <= 0) {
        clearInterval(interval.current);
        setSeconds(0);
        setMinutes(0);
        setHours(0);
        setDays(0);
        setShowCountdown(false);
      }
    }, 1000);
  };

  useEffect(() => {
    startCoundown();
    return () => {
      clearInterval(interval.current);
    };
  });

  return (
    <>
      <div className="home-nav-purple sticky top-0 z-1 black-bg w-full">
        <div className="max-width mx-auto nav-inline !font-space !px-2">
          <h2 className="!font-space">{host_name}</h2>

          <div className="countdown hidden md:flex !font-space">
            <h5 className="!font-space" id="days">
              {days}
            </h5>
            <h6 className="!font-space">Days</h6>
            <img className="dots" src={dots} />
            <h5 className="!font-space" id="hours">
              {hours}
            </h5>
            <h6 className="!font-space">Hours</h6>
            <img className="dots" src={dots} />
            <h5 className="!font-space" id="mins">
              {minutes}
            </h5>
            <h6 className="!font-space">Mins</h6>
            <img className="dots" src={dots} />
            <h5 className="!font-space" id="secs">
              {seconds}
            </h5>
            <h6 className="!font-space">Seconds</h6>
          </div>

          <a
            className="book-btn purple-btn desktop font-space !text-white"
            onClick={() => onRegister()}
          >
            Book your slot for ₹{registration_amount}
          </a>
          <a
            className="purple-btn register-btn mobile"
            onClick={() => onRegister()}
          >
            Register
          </a>
        </div>
        <div className="countdown flex md:hidden !px-2">
          <h5 className="!font-space" id="dayss">
            {days}
          </h5>
          <h6 className="!font-space">Days</h6>
          <img className="dots" src={dots} />
          <h5 className="!font-space" id="hourss">
            {hours}
          </h5>
          <h6 className="!font-space">Hours</h6>
          <img className="dots" src={dots} />
          <h5 className="!font-space" id="minss">
            {minutes}
          </h5>
          <h6 className="!font-space">Mins</h6>
          <img className="dots" src={dots} />
          <h5 className="!font-space" id="secss">
            {seconds}
          </h5>
          <h6 className="!font-space">Sec</h6>
        </div>
      </div>

      <div className="home-purple w-full">
        <div className="max-width mx-auto px-4">
          <div className="head-div">
            <h5>
              {moment(starts_at).format('ddd')}{' '}
              {moment(starts_at).format('lll')} -{' '}
              {moment(ends_at).format('hh:mm A')} IST
            </h5>
            <h1 className="font-space">{name}</h1>
            <h6>{excerpt}</h6>
            <div className="rating-div">
              <div
                className="book-btn purple-btn !bg-[#9831FF] !text-white !mb-0 !w-auto !items-center font-sans cursor-pointer"
                onClick={() => onRegister()}
              >
                Register at ₹{registration_amount}
              </div>
              <div className="vl"></div>
              <div className="rate-div">
                <h4>
                  <img src={stars} alt="" />
                  4.2
                </h4>
                <h6>1200+ Ratings</h6>
              </div>
            </div>

            {/* <progress id="" value="40" max="100"></progress>
            <div className="progress-div">
              <p>
                40%{' '}
                <span className="!font-space !normal-case">
                  Booked, Hurry Up
                </span>
              </p>
              <p>100%</p>
            </div> */}
          </div>

          <div
            className="creative-div"
            style={{
              backgroundImage: `url('${host_image_url}')`,
            }}
          >
            <div className="img-div">
              <div className="img-text">
                <h2 className="!font-space">{host_name}</h2>
                <h6 className="!font-space">{host_designation} </h6>
              </div>
              {/* <div className="img-foot">
                <p className="!font-space">
                  <img src={youtube} alt="" />
                  234k
                </p>
                <p className="!font-space">
                  <img src={linkedin} alt="" />
                  81k
                </p>
                <p className="!font-space">
                  <img src={instagram} alt="" />
                  129k
                </p>
              </div> */}
            </div>
          </div>
        </div>

        {keywords && keywords.length ? (
          <>
            <div className="brand-carousel">
              <div className="horizontal-scrolling-items">
                {keywords.split(',').map((k) => (
                  <div className="horizontal-scrolling-items__item">
                    <h4>
                      {k.trim()}{' '}
                      <span>
                        <img className="vr-line" src={vr} alt="" />
                      </span>
                    </h4>
                  </div>
                ))}
              </div>
            </div>

            <div className="brand-carousel brand-carousel1 margin-top-32">
              <div className="horizontal-scrolling-items2">
                {keywords.split(',').map((k) => (
                  <div className="horizontal-scrolling-items__item">
                    <h4>
                      {k.trim()}{' '}
                      <span>
                        <img className="vr-line" src={vr} alt="" />
                      </span>
                    </h4>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="learn w-full">
        <div className="max-width mx-auto px-4">
          <h1 className="!font-space text-center mx-auto">
            {points_covered_title ||
              'Learn what 200+ people did and applied in their corporate life.'}
          </h1>
          <div className="flex flex-col md:flex-row md:flex-wrap gap-x-4 gap-y-8">
            {points_covered.map((point) => (
              <div className="col-sm-12 col-md-4 mr-5 md:max-w-[30%]">
                <h2>{point.value.title}</h2>
                <h6>{point.value.body}</h6>
              </div>
            ))}
          </div>

          {/* <div className="pills">
            <p>
              <img src={tick} alt="" />
              Started a Startup
            </p>
            <p>
              <img src={tick} alt="" />
              Promoted to Psuedo Vice President
            </p>
            <p>
              <img src={tick} alt="" />
              Earned ₹2000
            </p>

            <p>
              <img src={tick} alt="" />
              Started a Startup
            </p>
            <p>
              <img src={tick} alt="" />
              Promoted to Psuedo Vice President
            </p>
            <p>
              <img src={tick} alt="" />
              Earned ₹2000
            </p>
          </div> */}
        </div>
      </div>

      {benefits.length > 0 && (
        <div className="workshop w-full">
          <div className="max-width mx-auto px-4">
            <div className="flex flex-col md:flex-row">
              <div className="flex flex-col items-start md:mr-20">
                <h5 className="purple-text">should you join</h5>
                <h1 className="!font-space">
                  {benefits_title || 'This workshop is for you if....'}
                </h1>

                <div className="flex flex-col gap-12 my-4">
                  {benefits.map((benefit) => (
                    <div className="text-div">
                      <img src={purple_rect} alt="" />
                      <p>{benefit.value.title}</p>
                    </div>
                  ))}
                </div>

                <a
                  className="get-btn d-none d-md-flex"
                  onClick={() => onRegister()}
                >
                  Book your slot for <span>₹{before_discount_amount}</span>₹
                  {registration_amount}{' '}
                </a>
                {/* <a className="get-btn d-flex d-md-none" href="">Get Slot for ₹200 </a> */}
              </div>

              {testimonials && testimonials.length > 0 ? (
                <div className="w-full md:w-[760px] cards tab-card">
                  <div className="cards-div">
                    {testimonials.map((testimonial) => (
                      <div className="card-div">
                        <h6>{testimonial.value.text}</h6>

                        <div className="card-footer">
                          {testimonial.value.image ? (
                            <img src={testimonial.value.image} alt="" />
                          ) : (
                            <></>
                          )}
                          <div className="card-foo">
                            <h4>{testimonial.value.author}</h4>
                            <p>{testimonial.value.designation}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="meet-creator w-full">
        <div className="max-width mx-auto px-4">
          <div className="creator-div">
            <div className="flex gap-8 w-full flex-col md:flex-row">
              <div className="md:w-[600px]">
                <div className="head-div">
                  <h5 className="purple-text !font-space">MEET THE CREATOR</h5>
                  <h1 className="!font-space">{host_name}</h1>
                </div>
                <h6>{host_bio}</h6>
              </div>
              <div className="flex justify-center items-start flex-1 md:mt-32">
                <img
                  className="rounded-lg w-max h-auto"
                  src={host_image_url}
                  alt=""
                />
              </div>
            </div>
            {/* <div className="carousel flex flex-row">
              <OwlCarousel
                responsive={{
                  0: {
                    items: 1,
                    stagePadding: 30,
                  },
                  768: {
                    items: 3,
                    stagePadding: 0,
                    margin: 120,
                  },
                }}
              >
                <div className="carousel-cell">
                  <img src={carol} alt="" />
                </div>
                <div className="carousel-cell">
                  <img src={carol2} alt="" />
                </div>
                <div className="carousel-cell">
                  <img src={carol} alt="" />
                </div>
                <div className="carousel-cell">
                  <img src={carol2} alt="" />
                </div>
                <div className="carousel-cell">
                  <img src={carol} alt="" />
                </div>
                <div className="carousel-cell">
                  <img src={carol2} alt="" />
                </div>
                <div className="carousel-cell">
                  <img src={carol} alt="" />
                </div>
                <div className="carousel-cell">
                  <img src={carol2} alt="" />
                </div>
              </OwlCarousel>
            </div> */}

            <div className="meet-footer flex flex-col gap-8">
              <div className="col-sm-12 col-md-7 d-flex row flex flex-wrap gap-8">
                {statistics.map((stat) => (
                  <div className="foot-div col-6">
                    <p>{stat.value.title}</p>
                    <h4 className="text-md">{stat.value.value} </h4>
                  </div>
                ))}
              </div>
              <div className="col-sm-12 col-md-4 flex justify-center">
                <a className="get-btn purple-btn" onClick={() => onRegister()}>
                  Book your slot for <span>₹{before_discount_amount}</span> ₹
                  {registration_amount}
                </a>
              </div>
            </div>
          </div>

          {/* <div className="foo">
            <h5 className="purple-text">Featured In</h5>

            <div className="foo-div">
              <img src={press1} alt="" />
              <img src={press2} alt="" />
              <img src={press3} alt="" />
              <img src={press4} alt="" />
            </div>
          </div> */}
        </div>
      </div>

      <div className="hurry-purple w-full">
        <div className="max-width mx-auto px-4">
          <div className="head-div !flex flex-col md:!flex-row">
            <div>
              <h5 className="purple-text">MAKE HURRY</h5>
              <h1 className="!font-space">Time is running out, Book Now</h1>
            </div>
            <a className="get-btn purple-btn" onClick={() => onRegister()}>
              Register at <span>₹{before_discount_amount}</span> ₹
              {registration_amount}
            </a>
          </div>
          <div className="counter hidden md:flex flex-row">
            <div className="time">
              <h4 id="Days">{days} Days</h4>
            </div>
            <div className="time">
              <h4 id="Hours">{hours} Hours</h4>
            </div>

            <div className="time">
              <h4 id="Mins">{minutes} Mins</h4>
            </div>
            <div className="time">
              <h4 id="Secs">{seconds} Seconds</h4>
            </div>
          </div>

          <div className="flex md:hidden counter d-flex d-sm-none ">
            <div className="time ">
              <h4 id="Dayss">{days}</h4>
              <h4 className="!text-[#a9a9a9]">Days</h4>
            </div>
            <div className="time ">
              <h4 id="Hourss">{hours}</h4>
              <h4 className="!text-[#a9a9a9]">Hours</h4>
            </div>
          </div>
          <div className="flex md:hidden counter d-flex d-sm-none ">
            <div className="time ">
              <h4 id="Minss">{minutes}</h4>
              <h4 className="!text-[#a9a9a9]">Mins</h4>
            </div>
            <div className="time ">
              <h4 id="Secss">{seconds}</h4>
              <h4 className="!text-[#a9a9a9]">Sec</h4>
            </div>
          </div>
        </div>
      </div>

      {faqs && faqs.length > 0 ? (
        <div className="faq w-full">
          <div className="max-width mx-auto px-4">
            <div className="flex flex-col md:flex-row">
              <div className="col-5 w-full md:w-[60%] mr-10">
                <h5 className="purple-text">FAQs</h5>
                <h1 className="!font-space">
                  Frequently Asked Questions, Answered.
                </h1>
              </div>

              <div className="col-7 w-full">
                <div className="text-div !flex flex-col w-full">
                  {faqs.map((faq) => (
                    <FAQBox
                      title={faq.value.question}
                      content={faq.value.answer}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* <div className="footer w-full">
        <div className="max-width mx-auto">
          <div className="head-div">
            <h1>MOSES KAUL</h1>
            <div className="socials">
              <a href="">Twitter</a>
              <a href="">LinkedIn</a>
              <a href="">Instagram</a>
            </div>
          </div>

          <div className="foot-div">
            <div className="tab-links">
              <a href="">Help</a>
              <a href="">Privacy</a>
            </div>
            <p>© 2077 Highsocial</p>
          </div>
        </div>
      </div> */}
    </>
  );
};
