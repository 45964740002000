import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './benefits.css';
import moment from 'moment-timezone';

const Header = ({
  pageTitle,
  showBtn = true,
  countdownTime: closeTime,
  onRegisterClick,
}: {
  pageTitle: string;
  showBtn?: boolean;
  countdownTime: string;
  onRegisterClick: Function;
}) => {
  const navigate = useNavigate();

  const [showCoundown, setShowCountdown] = useState(true);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const endTime = moment(closeTime).toDate().getTime();

  let interval: any = useRef();

  const startCountdown = () => {
    interval = setInterval(() => {
      const now = new Date().getTime();
      const timeLeft = (endTime - now) / 1000;
      setSeconds(Math.floor(timeLeft % 60));
      setMinutes(Math.floor(timeLeft / 60) % 60);
      setHours(Math.floor(timeLeft / (60 * 60)) % 24);
      setDays(Math.floor(timeLeft / (60 * 60 * 24)));

      if (timeLeft <= 0) {
        clearInterval(interval.current);
        setSeconds(0);
        setMinutes(0);
        setHours(0);
        setDays(0);
        setShowCountdown(false);
      }
    }, 1000);
  };

  useEffect(() => {
    startCountdown();
    return () => {
      clearInterval(interval.current);
    };
  });

  return (
    <>
      <div className="w-full sticky z-40 top-0 bg-white BricolageFont centerPage border-b-1 border-[#F2F2F2]">
        <div className="flex justify-between items-center w-full py-3 max-w-[1200px] px-3">
          {/* <img src={Young} alt="Logo" className="h-16 cursor-pointer" onClick={() => {
                        navigate('/')
                    }} /> */}
          {/* <nav className="flex justify-center space-x-4">
                        <Link to="/workshop1" className="font-medium px-3 py-2 text-slate-700 rounded-lg hover:bg-slate-100 hover:text-slate-900 active">Workshop 1</Link>
                        <Link to="/workshop2" className="font-medium px-3 py-2 text-slate-700 rounded-lg hover:bg-slate-100 hover:text-slate-900">Workshop 2</Link>
                        <Link to="/workshop3" className="font-medium px-3 py-2 text-slate-700 rounded-lg hover:bg-slate-100 hover:text-slate-900">Workshop 3</Link>
                    </nav> */}
          <div className="">
            <p className="lg:text-[24px] text-[20px] font-semibold">
              {pageTitle}
            </p>
          </div>
          <div
            className={`border rounded-full py-2 px-4 ml-2 sm:flex hidden ${
              showCoundown ? 'flex' : 'hidden'
            }`}
          >
            <div className="sm:flex items-center">
              <h6 className="font-semibold text-center">{days}</h6>
              <span className="text-[#666] font-normal sm:ml-2">Days</span>
            </div>
            <div className="sm:flex items-center mx-2">
              <h6 className="font-semibold text-center">{hours}</h6>
              <span className="text-[#666] font-normal sm:ml-2">Hours</span>
            </div>
            <div className="sm:flex items-center mx-2">
              <h6 className="font-semibold text-center">{minutes}</h6>
              <span className="text-[#666] font-normal sm:ml-2">Mins</span>
            </div>
            <div className="sm:flex items-center">
              <h6 className="font-semibold text-center">{seconds}</h6>
              <span className="text-[#666] font-normal sm:ml-2">Seconds</span>
            </div>

            {/* <h6 className='font-semibold mx-2'>{hours} <span className='text-[#666] font-normal'>Hours</span></h6>
                        <h6 className='font-semibold mx-2'>{minutes} <span className='text-[#666] font-normal'>Mins</span></h6>
                        <h6 className='font-semibold'>{seconds} <span className='text-[#666] font-normal'>Seconds</span></h6> */}
          </div>
          {showBtn ? (
            <div className="w-fit" onClick={() => onRegisterClick()}>
              <button
                type="button"
                className="text-white bg-[#FF6F31] hover:bg-7F56D9 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 sm:ml-0 ml-2 "
              >
                Register
              </button>
            </div>
          ) : null}
        </div>
        <div
          className={`w-full bg-[#F2F2F2] items-center border-b-1 border-[#F2F2F2] py-2 px-2 sm:hidden gap-2 justify-center ${
            showCoundown ? 'flex' : 'hidden'
          }`}
        >
          <div className="flex items-center gap-1">
            <h6 className="font-semibold text-center text-[14px]">{days}</h6>
            <span className="text-[#666] font-normal text-[14px]">Days</span>
          </div>
          :
          <div className="flex items-center gap-1">
            <h6 className="font-semibold text-center text-[14px]">{hours}</h6>
            <span className="text-[#666] font-normal text-[14px]">Hours</span>
          </div>
          :
          <div className="flex items-center gap-1">
            <h6 className="font-semibold text-center text-[14px]">{minutes}</h6>
            <span className="text-[#666] font-normal text-[14px]">Mins</span>
          </div>
          :
          <div className="flex items-center gap-1">
            <h6 className="font-semibold text-center text-[14px]">{seconds}</h6>
            <span className="text-[#666] font-normal text-[14px]">Seconds</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
