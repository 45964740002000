import React from 'react';
import './AboutCreator.css';
import instagram from '../../assets/instagram.svg';
import youtube from '../../assets/youtube.svg';
import profile2 from '../../assets/varun420x420-1.jpg';
import sahiba from '../../assets/sahiba.jpg';
import { API } from '../../types/api';

const AboutCreator = ({
  webinar,
}: {
  webinar: API.Responses.WebinarDetails;
}) => {
  const { host_bio, host_image_url, statistics } = webinar.page;

  return (
    <div className="lg:px-[150px] lg:py-[100px] w-full bg-gradient-black text-white sm:px-[3rem] p-4 !py-16 gap-16 BricolageFont centerPage">
      <div className="max-w-[1200px]">
        <h3 className="sm:hidden flex text-[28px]  pb-[24px] font-semibold leading-6 text-left">
          About the Creator
        </h3>
        <div className="w-full sm:flex gap-4">
          <div className="sm:w-1/2 w-full">
            <img
              className="sm:flex hidden rounded-xl shadow h-[500px] w-[500px] object-cover"
              src={host_image_url}
              alt=""
            />
          </div>
          <div className="sm:w-1/2 w-full sm:pt-0 pt-4">
            <h3 className="sm:flex hidden text-[44px] pb-[24px] font-semibold leading-6 text-left">
              About the Creator
            </h3>
            <p className="pb-[16px]">{host_bio}</p>

            <img
              className="sm:hidden flex rounded-xl shadow"
              src={host_image_url}
              alt=""
            />
          </div>
        </div>

        <div className="w-full sm:pt-[80px] pt-[40px]">
          <div className="hidden sm:grid sm:grid-cols-4 grid-cols-2 gap-4">
            {statistics.map((stat) => (
              <div className="creatorCard border-1 rounded-md border-[#444]">
                <h3 className="text-[24px] font-semibold">
                  {stat.value.value}
                </h3>
                <p className="">{stat.value.title}</p>
              </div>
            ))}
          </div>
          <div className="sm:hidden grid grid-cols-1 gap-4">
            <div className="grid grid-cols-2 gap-4">
              {statistics.map((stat) => (
                <div className="creatorCard border-1 rounded-md border-[#444]">
                  <h3 className="text-[24px] font-semibold">
                    {stat.value.value}
                  </h3>
                  <p className="">{stat.value.title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCreator;
