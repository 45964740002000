import React from 'react';
import './Features.css';
import { Link } from 'react-router-dom';
import { API } from '../../types/api';

const FeatureCard = ({ title, content }: { title: string; content: any }) => {
  return (
    <div className="featureCard sm:mb-0 mb-3">
      <div className="p-3">
        <h6 className="text-[16px] font-semibold tracking-tight ">{title}</h6>
        <p className="mt-1">{content}</p>
      </div>
    </div>
  );
};

const Features = ({
  webinar,
  onRegister,
}: {
  webinar: API.Responses.WebinarDetails;
  onRegister: Function;
}) => {
  const { points_covered_title, points_covered, registration_amount } =
    webinar.page;

  return (
    <div className="lg:px-[150px] lg:py-[100px] w-full text-black sm:px-[3rem] p-4 !py-16 bg-white BricolageFont centerPage">
      <div className="max-w-[1200px] sm:items-center flex flex-col">
        <div className="pb-[28px] text-center">
          <h3 className="sm:text-[44px] text-[28px] font-bold tracking-tight">
            {points_covered_title || 'Why attend this workshop?'}
          </h3>
        </div>
        <div className="sm:grid sm:grid-cols-2 gap-4 text-left">
          {points_covered.map((it) => (
            <FeatureCard title={it.value.title} content={it.value.body} />
          ))}
        </div>
        <div className="pt-[32px] text-center" onClick={() => onRegister()}>
          <div className="sm:w-fit grid w-full">
            <button
              type="button"
              className="text-white bg-[#FF6F31] hover:bg-black focus:ring-4 focus:ring-blue-300 text-[18px] font-semibold leading-7 rounded-lg px-5 py-2.5 mb-2"
            >
              Book your Slot for ₹{registration_amount}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
