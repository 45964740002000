export namespace API {
  export namespace Responses {
    export interface RegisterWebinar {
      success: boolean;
      message: string;
      registration?: {
        pk: number;
        webinar_id: number;
        payment_gateway: 'razorpay';
        attendee_name: string;
        attendee_email: string;
        attendee_phone: string;
        amount_total: number;
        pg_order_id: string;
        pg_receipt_id: string | null;
      };
    }
    export interface CheckCouponCode {
      success: boolean;
      coupon: {
        pk: number;
        coupon_code: string;
        coupon_value: number;
        isPercentage: string;
        coupons_available: number;
        expiry_date: string;
      };
    }

    export interface WebinarDetails {
      page: {
        pk: number;
        name: string;
        excerpt: string;
        template_choosen: string;
        starts_at: string;
        ends_at: string;
        closes_at: string;
        organizer_email: string;
        before_discount_amount: number;
        registration_amount: number;
        host_name: string;
        host_designation: string;
        host_bio: string | null;
        host_image_url: string;
        host_image_alt_url: string | null;
        coHosts:
          | {
              pk: number;
              name: string;
              designation: string;
              bio: string | null;
              image_url: string;
            }[]
          | null;
        address: string;
        isOnline: boolean;
        keywords: string | null;
        statistics_title: string;
        about_workshop_title: string;
        about_workshop: string;
        points_covered_title: string;
        benefits_title: string;
        testimonials_title: string;
        faq_title: string;
        host_external_connection: [];
        statistics: {
          type: string;
          value: {
            title: string;
            value: string;
            icon: string;
          };
          id: string;
        }[];
        benefits: {
          type: string;
          value: {
            title: string;
            body: string;
          };
          id: string;
        }[];
        points_covered: {
          type: string;
          value: {
            title: string;
            body: string;
          };
          id: string;
        }[];
        testimonials: {
          type: 'testimonial';
          value: {
            text: string;
            author: string;
            designation: string;
            image: string;
          };
          id: string;
        }[];
        hashtags: [];
        faqs: {
          type: string;
          value: {
            question: string;
            answer: string;
          };
          id: string;
        }[];
      };
    }
  }
}

export const states = [
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
];
