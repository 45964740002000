import React from 'react';
import './benefits.css';

const Footer = () => {
  return (
    <div className="w-full px-[20px] lg:px-[64px] bg-[#FFF] text-[#1F1F1F] BricolageFont centerPage">
      <div className="max-w-[1200px]">
        <div className="py-3 lg:flex">
          <div className="w-full flex pb-3 gap-4 flex-col-reverse lg:flex-row justify-center items-center lg:justify-between sm:w-[1060px]">
            <p className="text-xs font-semibold">
              © Big Bang Academy. All rights reserved.
            </p>
            <div className="flex gap-4">
              {/* <a href="tel:+918652631027" className="text-xs font-semibold">Call us</a> */}
              <a
                href="mailto:workshop@bigbang.social"
                className="text-xs font-semibold"
              >
                Email us
              </a>
            </div>
          </div>

          {/* <div className="w-full lg:w-1/2 flex lg:justify-end pb-3"> */}
          {/* <button type="submit" className="bg-gray-500 text-white p-2 rounded text-sm w-auto">
                        Skip Now
                    </button> */}
          {/* <div className="text-xs">
                            <span className=''>Term</span>
                            <span className='px-2'>Privacy</span>
                            <span className=''>Cookies</span>
                        </div> */}
          {/* </div> */}
        </div>
        {/* <div className='w-full flex pb-3 gap-4 flex-col-reverse lg:flex-row justify-center items-center lg:justify-between'>
                    <div className='w-1/2'>
                        <p className="text-xs">© Big Bang Academy. All rights reserved.</p>
                    </div>
                    <div className='w-1/2'>
                        <div className="flex gap-4">
                            <a href="tel:+918652631027" className="text-xs">Call us</a>
                            <a href="mailto:workshop@bigbang.social" className="text-xs">Email us</a>
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default Footer;
