import React, { useRef } from 'react'
import { API } from '../../types/api';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import avatar from '../../assets/avatar_.png'
import bg from '../../assets/features-bg.svg'

export const Testimonials = (
	{
		title,
		items
	} :
	{
		title: string,
		items: API.Responses.WebinarDetails['page']['testimonials']
	}
) => {
	const owlRef = useRef(null)

	const nextButton = () => {
		if(owlRef.current) {
			owlRef.current.next()
		}
	}

	const prevButton = () => {
		if(owlRef.current) {
			owlRef.current.prev()
		}
	}

  return (
    <div className='w-full bg-[#FFF127] py-24 pb-40 flex flex-col text-black relative'>

		<div className='px-5 md:px-32 flex flex-col'>
			<div className='flex flex-col'>
				<p className='uppercase font-instrument'>Testimonials</p>

				<p className='uppercase font-bebas mt-2 text-4xl md:text-6xl'>{title}</p>
			</div>

			<div className='flex justify-start md:justify-end w-full mt-2 mb-5 md:mb-0'>
				<div className='flex'>
					<div onClick={prevButton} className='border cursor-pointer border-[#E0D422] p-3 flex mr-4'>
						<ArrowBackIcon />
					</div>
				
					<div onClick={nextButton} className='bg-[#1F1F1F] cursor-pointer p-3 flex'>
						<ArrowForwardIcon sx={{color:'#fff'}} />
					</div>
				</div>
				
			</div>

			<div className='flex w-full mt-10 z-10'>
				<OwlCarousel ref={owlRef}
				className='relative yellowTestimonials'
				
				margin={50}
				stagePadding={5}
				responsive={{
					0: {
						items: 1
					}, 
					768: {
						items: 2
					}
				}}
				>
					{
						items.map((i) => {
							return (
								<div className='bg-white mb-2 w-full p-5 flex flex-col mr-5 shadow-[4px_4px_0px_0px_#B9AD02]'>
									{/* <div className=''>
										<p className='text-2xl font-instrument font-semibold'>Lorem</p>
									</div> */}

									<div className='mt-1'>
										<p className='text-[#6F6C90] font-instrument'>{i.value.text}</p>
									</div>

									<div className='flex items-center mt-5'>
										<div>
											<img className='w-12' src={i.value.image} />
										</div>

										<div className='flex flex-col ml-4'>
											<p className='font-grot text-[#170F49] font-medium'>{i.value.author}</p>
											<p className='text-[#6F6C90]'>{i.value.designation}</p>
										</div>
									</div>
								</div>
							)
						})
					}

				</OwlCarousel>

			</div>
		</div>


		<div className='w-full flex justify-center absolute bottom-0'>
			<img className='w-[600px] ' src={bg} />
		</div>
    </div>
  )
}
