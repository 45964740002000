import React, { useState } from 'react';
import AboutWorkshop from './AboutWorkshop';
import AboutCreator from './AboutCreator';
import Benefits from './Benefits';
import WorkshopDetail from './WorkshopDetail';
import Testimonial from './Testimonial';
import Features from './Features';
import TimeRemains from './TimeRemains';

import ReactPixel from 'react-facebook-pixel';
import { fbPixelConfig } from '../../config/fb-pixel';
import { API } from '../../types/api';

export const OrangeWorkshop = ({
  webinar,
  onRegister,
}: {
  webinar: API.Responses.WebinarDetails;
  onRegister: Function;
}) => {
  ReactPixel.pageView();
  ReactPixel.trackCustom('page-view-landing', {
    siteIdentifier: fbPixelConfig.siteIdentifier,
  });

  return (
    <div className="contents">
      <WorkshopDetail webinar={webinar} onRegister={onRegister} />
      <AboutWorkshop webinar={webinar} onRegister={onRegister} />
      <AboutCreator webinar={webinar} />
      <Features webinar={webinar} onRegister={onRegister} />
      {webinar.page.benefits.length > 0 && <Benefits webinar={webinar} />}
      {/* <Testimonial /> */}
      <TimeRemains webinar={webinar} onRegister={onRegister} />
    </div>
  );
};
