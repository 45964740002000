import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  SxProps,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { API, states } from '../../types/api';
import ReactPixel from 'react-facebook-pixel';
import moment from 'moment-timezone';
import api from '../../services/api';
import razorpay from '../../services/razorpay';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Check } from '@mui/icons-material';

const style: SxProps = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  height: '85vh',
  overflow: 'hidden',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  border: 'none',
  'border:focus': 'none',
  'outline:focus': 'none',
  borderRadius: '20px',
  boxShadow: 24,
  paddingY: 2,
  paddingX: 8,
};

export const RegisterModal = ({
  show,
  webinar,
  setShow,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  webinar: API.Responses.WebinarDetails;
}) => {
  const { closes_at, registration_amount } = webinar.page;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [showCoupon, setShowCoupon] = useState<boolean>(false);
  const [couponChecking, setCouponChecking] = useState<boolean>(false);
  const [couponCodeTemp, setCouponCodeTemp] = useState<string>('');
  const [couponCodeError, setCouponCodeError] = useState<string>('');
  const [tax, setTax] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);
  const [couponResponse, setCouponResponse] =
    useState<API.Responses.CheckCouponCode['coupon']>();

  const checkCouponCode = async () => {
    setCouponCodeError('');
    if (!couponCodeTemp.length) {
      return setCouponCodeError('Invalid coupon code');
    }

    const response = await api.Webinar.checkCouponCode(
      webinar.page.pk,
      couponCodeTemp
    );

    if ('error' in response) {
      console.log({ response });
      return setCouponCodeError(
        response.error.message || 'Coupon code invalid or expired'
      );
    }

    setCouponResponse(response.coupon);

    const { coupon_value, isPercentage } = response.coupon;

    let _discount = isPercentage
      ? registration_amount * (coupon_value / 100)
      : coupon_value;

    _discount = Math.min(_discount, registration_amount);

    console.log({ _discount });

    setDiscount(_discount);

    setFormProps({
      ...formProps,
      couponCode: couponCodeTemp,
    });
  };

  const [formProps, setFormProps] = useState<{
    name?: string;
    email?: string;
    phone?: string;
    state?: string;
    couponCode?: string;
  }>({
    phone: '+91',
  });

  useEffect(() => {
    setError('');
    if (!formProps.phone || formProps.phone.length === 0) {
      setFormProps({
        ...formProps,
        phone: '+91',
      });
    }
  }, [JSON.stringify(formProps)]);

  useEffect(() => {
    const _tax = registration_amount * 0.18;
    setTax(_tax);
  }, []);

  useEffect(() => {
    console.log({ discount });
    if (discount > 0) {
      setTax((registration_amount - discount) * 0.18);
    }
  }, [discount]);

  const onSubmit = async (e: any) => {
    ReactPixel.trackCustom('register-form-submit-click', {
      siteIdentifier: webinar.page.pk,
      name: webinar.page.name,
      formProps,
    });
    setLoading(true);
    const { name, email, phone, state, couponCode } = formProps;

    if (!name || !email || !phone || !state) {
      setLoading(false);
      setError('Please fill all the fields');
      ReactPixel.trackCustom('register-form-incomplete', {
        siteIdentifier: webinar.page.pk,
        name: webinar.page.name,
        formProps,
      });
      return;
    }

    try {
      const response = await api.Webinar.registerV2(webinar.page.pk, {
        name,
        phone,
        email,
        state,
        couponCode,
      });

      ReactPixel.trackCustom('register-form-api-called', {
        siteIdentifier: webinar.page.pk,
        name: webinar.page.name,
        formProps,
        response,
      });
      const { success, registration, message } = response;

      if (!success) {
        ReactPixel.trackCustom('register-form-api-error', {
          siteIdentifier: webinar.page.pk,
          name: webinar.page.name,
          formProps,
          response,
        });
        setLoading(false);
        setError(message);
        return;
      }

      if (registration) {
        const {
          pg_order_id,
          attendee_name,
          attendee_phone,
          attendee_email,
          amount_total,
        } = registration;

        if (amount_total > 0) {
          const pgResponse = await razorpay.paymentHandlerV2(
            e,
            pg_order_id,
            {
              name: attendee_name,
              email: attendee_email,
              contact: attendee_phone,
            },
            webinar
          );
        } else {
          window.localStorage.setItem('email', attendee_email);
          window.location.href = '/success';
          return;
        }
      } else {
        setLoading(false);
        setError('Something went wrong. Please try again.');
        return;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError('Something went wrong. Please try again.');
      return;
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [error]);

  return (
    <div className="modal">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={show}
        onClose={() => {
          setShow(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={show}>
          <Box sx={style}>
            <div className="register-modal mx-auto text-black lg:flex gap-4 justify-center h-full overflow-y-auto">
              {new Date() > moment(closes_at).toDate() ? (
                <div className="w-full min-h-screen grid mb-6 px-4 lg:px-16">
                  <div className="mt-[4px] p-2 leading-4 ">
                    Sorry! We've stopped accepting new registrations.
                  </div>
                </div>
              ) : (
                <div className="w-full grid mb-6 lg:px-16">
                  <h3 className="text-[24px] pb-[16px] font-bold tracking-tight text-center">
                    Registration Details
                  </h3>
                  <div className="mt-[4px] p-2 leading-4 ">
                    <p className="pb-3 leading-4 tracking-normal text-left">
                      Name *
                    </p>
                    <input
                      placeholder="John Doe"
                      type="text"
                      className="px-2 py-2 text-sm w-full font-medium border rounded leading-4 tracking-normal text-left text-black hover:input-hover"
                      onChange={(e) => {
                        const v = e.target.value;
                        setFormProps({
                          ...formProps,
                          name: v,
                        });
                      }}
                    />
                  </div>
                  <div className="mt-[4px] p-2 leading-4 ">
                    <p className="pb-3 leading-4 tracking-normal text-left">
                      Email *
                    </p>
                    <input
                      placeholder="name@org.com"
                      type="email"
                      className="px-2 py-2 text-sm w-full font-medium border rounded leading-4 tracking-normal text-left text-black input-focus-visible input-default focus:input-focus hover:input-hover focus-visible:input-focus-visible"
                      onChange={(e) => {
                        const v = e.target.value;
                        setFormProps({
                          ...formProps,
                          email: v,
                        });
                      }}
                    />
                  </div>
                  <div className="mt-[4px] p-2 leading-4 ">
                    <p className="pb-3 leading-4 tracking-normal text-left">
                      Phone *
                    </p>
                    {/* <input
                      placeholder="9876543210"
                      type="number"
                      className="px-2 py-2 text-sm w-full font-medium border rounded leading-4 tracking-normal text-left text-black input-focus-visible input-default focus:input-focus hover:input-hover focus-visible:input-focus-visible"
                      onChange={(e) => {
                        const v = e.target.value;
                        setFormProps({
                          ...formProps,
                          phone: v,
                        });
                      }}
                    /> */}
                    <PhoneInput
                      country={'in'}
                      value={formProps.phone}
                      containerClass="border rounded leading-4 tracking-normal text-left text-black"
                      containerStyle={{
                        width: 'full',
                        boxShadow: 'none',
                      }}
                      buttonStyle={{
                        border: 'none',
                      }}
                      // inputClass="text-black input-focus-visible input-default focus:input-focus hover:input-hover focus-visible:input-focus-visible"
                      inputStyle={{
                        width: 'inherit',
                        border: 'none',
                      }}
                      // inputClass="px-2 py-2 text-sm w-full font-medium border rounded leading-4 tracking-normal text-left text-black input-focus-visible input-default focus:input-focus hover:input-hover focus-visible:input-focus-visible"
                      onChange={(phone) => {
                        setFormProps({
                          ...formProps,
                          phone,
                        });
                      }}
                    />
                  </div>
                  <div className="mt-[4px] p-2 leading-4 ">
                    <p className="pb-3 leading-4 tracking-normal text-left">
                      State *
                    </p>

                    <select
                      className="px-2 py-2 text-sm w-full font-medium border rounded leading-4 tracking-normal text-left text-black input-focus-visible input-default focus:input-focus hover:input-hover focus-visible:input-focus-visible"
                      id="cars"
                      name="cars"
                      onChange={(e) => {
                        const v = e.target.value;
                        setFormProps({
                          ...formProps,
                          state: v,
                        });
                      }}
                    >
                      <option value="">--- Select ---</option>
                      {states.map((state) => (
                        <option value={state}>{state}</option>
                      ))}
                    </select>
                  </div>

                  {!couponResponse && showCoupon ? (
                    <div className="mt-[4px] p-2 leading-4 ">
                      <p className="pb-3 leading-4 tracking-normal text-left">
                        Coupon Code
                      </p>
                      <div className="w-full flex justify-between">
                        <input
                          placeholder="GIVEMESOME"
                          type="text"
                          className="px-2 w-[calc(100%-60px)] py-2 text-sm font-medium border rounded leading-4 tracking-normal text-left text-black hover:input-hover"
                          onChange={(e) => {
                            const v = e.target.value;
                            setCouponCodeTemp(v);
                          }}
                        />
                        <button
                          className="flex w-[55px] items-center justify-center bg-black text-white border border-solid border-[#1F1F1F] rounded-md font-medium"
                          onClick={() => {
                            checkCouponCode();
                          }}
                          // disabled={loading}
                        >
                          <Check />
                        </button>
                      </div>
                    </div>
                  ) : couponResponse ? (
                    <></>
                  ) : (
                    <div className="mt-[4px] p-2 leading-4 ">
                      <div
                        className="underline cursor-pointer text-xs"
                        onClick={() => {
                          setShowCoupon(true);
                        }}
                      >
                        Apply Coupon Code
                      </div>
                    </div>
                  )}

                  {couponCodeError && (
                    <p className="text-sm text-[#FF0000]">{couponCodeError}</p>
                  )}

                  <div className="my-8 flex gap-4 flex-col">
                    <b>Payment info</b>
                    {couponResponse ? (
                      <p className="text-sm text-green-700 font-semibold leading-4">
                        {couponResponse.coupon_code.toUpperCase()}:{' '}
                        {couponResponse.isPercentage
                          ? `${couponResponse.coupon_value}%`
                          : `₹${couponResponse.coupon_value}`}{' '}
                        discount applied
                      </p>
                    ) : (
                      <></>
                    )}
                    <hr />
                    <div className="flex justify-between">
                      <p>Workshop fee</p>
                      <p>₹{registration_amount.toFixed(2)}</p>
                    </div>
                    {discount > 0 ? (
                      <div className="flex justify-between">
                        <p>Coupon discount</p>
                        <p>(-) ₹{discount.toFixed(2)}</p>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="flex justify-between">
                      <p>GST @ 18%</p>
                      <p>₹{tax.toFixed(2)}</p>
                    </div>
                    <hr />
                    <div className="flex justify-between">
                      <b>Total</b>
                      <b>
                        ₹{(registration_amount - discount + tax).toFixed(2)}
                      </b>
                    </div>
                  </div>

                  <p className="text-sm text-[#FF0000]">{error}</p>

                  <button
                    className="flex w-full items-center justify-center bg-black text-white px-3 py-2 border border-solid border-[#1F1F1F] rounded-lg font-medium h-[49px]"
                    onClick={onSubmit}
                    disabled={loading}
                  >
                    {loading ? 'Loading...' : 'Register'}
                  </button>
                </div>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
