import React from 'react'
import { API } from '../../types/api'

import creator from '../../assets/creator-img.png'

export const Creator = (
    {
        image,
        name,
        bio,
        statistics
    }:
    {
        image: string,
        name: string,
        bio: string,
        statistics: API.Responses.WebinarDetails['page']['statistics']
    }
) => {
  return (
    <div className='w-full flex flex-col md:flex-row bg-[#1F1F1F] py-24 px-5 md:px-32'>
        <div className='w-full md:w-[40%] flex justify-center md:justify-end md:px-10'>
            <div className='relative'>
                <img className='w-full md:w-[500px] h-[400px] md:h-[500px] object-cover' src={image} />

                <div className='absolute bottom-0 p-5 w-full bg-gradient-to-t from-black/90 to-black/5'>
                    <p className='uppercase text-[#FFF127]  font-instrument'>meet the creator</p>

                    <p className='text-white font-bebas uppercase text-5xl mt-2'>{name}</p>
                </div>
            </div>
        </div>

        <div className='w-full md:w-[60%] flex flex-col mt-10 md:mt-0 md:px-10'>
            <div className='w-full flex flex-col font-instrument '>
                <p className='text-lg opacity-70'>{bio}</p>
            </div>
            
            {
                statistics && statistics.length > 0 ?
                <div className='py-5 border-t border-[#323232] w-full mt-10 flex flex-wrap'>
                    {
                    statistics.map((i) => {
                        return (

                            <div className='w-1/2 flex flex-col items-start mb-5'>
                                <p className='font-instrument opacity-70 text-sm'>{i.value.title}</p>

                                <p className='text-xl font-instrument font-semibold mt-2'>{i.value.value}</p>
                            </div>

                        )
                    })
                    }
                </div>
                :
                <></>
            }
        </div>
    </div>
  )
}
