import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppRouter from './AppRouter';
import ReactPixel from 'react-facebook-pixel';
import { fbPixelConfig } from './config/fb-pixel';
import moment from 'moment-timezone';
moment.tz.setDefault('Asia/Calcutta');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
ReactPixel.init(fbPixelConfig.pixelId, undefined, {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
});
root.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
