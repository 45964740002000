import arrowRight from '../../assets/RoundArrowRight.png';
import calendar from '../../assets/calender.svg';
import sahiba from '../../assets/sahiba.jpg';
import './benefits.css';

import { Link } from 'react-router-dom';
import { API } from '../../types/api';
import moment from 'moment-timezone';

const WorkshopDetail = ({
  webinar,
  onRegister,
}: {
  webinar: API.Responses.WebinarDetails;
  onRegister: Function;
}) => {
  const {
    name,
    starts_at,
    ends_at,
    host_name,
    host_designation,
    host_image_url,
    registration_amount,
  } = webinar.page;

  return (
    <div className="lg:flex lg:px-[150px] lg:py-[100px] w-full text-black p-4 !py-16 sm:px-[3rem] bg-white text-left gap-16 BricolageFont centerPage">
      <div className="max-w-[1200px] sm:grid sm:grid-cols-2 gap-4">
        <div className="w-full  flex-col gap-2 gap-lg-4 self-center grid sm:text-left text-center">
          {/* <label className='text-[20px] font-semibold mb-[16px]'>Hosted by <span className='text-[#FF6F31] text-[20px]'>Bigbang.social</span></label> */}
          <h3 className="text-[32px] font-bold mb-[24px] text-left">
            {name} by{' '}
            <span className="text-[#FF6F31] text-[32px]">{host_name}</span>
          </h3>
          {/* <p className='text-[16px]'>Transition from your current job and build an exciting career as a Product Marketer</p> */}
          <div className="w-fit flex p-4 border rounded-full border-[#F2F2F2] mb-[16px]">
            <img src={calendar} className="w-[20px] mr-6" />
            <p className="font-bold">
              <span className="text-[#666] font-medium">
                {moment(starts_at).format('dddd')},{' '}
              </span>
              {moment(starts_at).format('lll')} -{' '}
              {moment(ends_at).format('hh:mm A')} IST
            </p>
          </div>
          <div
            className="sm:w-fit grid w-full my-[15px] lg:ml-0"
            onClick={() => onRegister()}
          >
            <button
              type="button"
              className="flex items-center text-white bg-[#FF6F31] hover:bg-black focus:ring-4 focus:ring-blue-300 font-semibold rounded-lg text-sm sm:px-5 px-10 py-2.5 mb-2"
            >
              <span className="text-[18px] sm:mr-4 mr-auto">
                Book your Seats at ₹{registration_amount}
              </span>
              <img src={arrowRight} alt="" />
            </button>
          </div>
        </div>
        <div className="w-full  justify-center lg:justify-end items-center flex pt-8">
          <div className="max-w-[28rem] bg-[#E8DEFF] rounded-xl">
            <figure className="relative max-w-sm transition-all duration-300 filter">
              <img className="rounded-xl shadow" src={host_image_url} alt="" />
              <figcaption className="absolute px-4 text-lg bottom-0 bg-white widthWeb rounded-xl">
                <div className="p-3 flex justify-between">
                  <div className="">
                    <h6 className="text-[19px] font-semibold">{host_name}</h6>
                    <p className="text-[#666] text-[14px]">
                      {host_designation}{' '}
                    </p>
                  </div>
                  <div className="">
                    {/* <img className="rounded-xl shadow" src={logo} alt="" /> */}
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopDetail;
