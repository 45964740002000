import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './blue.css';

import dots from '../../assets/purple/dots.png';
import stars from '../../assets/purple/stars.png';
import youtube from '../../assets/youtube.svg';
import linkedin from '../../assets/linkedin.svg';
import instagram from '../../assets/instagram.svg';
import vr from '../../assets/purple/vr.png';
import tick from '../../assets/purple/tick.png';
import purple_rect from '../../assets/purple/purple-rect.png';
import avatar from '../../assets/purple/avatar.png';
import carol from '../../assets/purple/carol-img1.png';
import carol2 from '../../assets/purple/carol-img2.png';
import press1 from '../../assets/purple/Press logo1.png';
import press2 from '../../assets/purple/Press logo2.png';
import press3 from '../../assets/purple/Press logo3.png';
import press4 from '../../assets/purple/Press logo4.png';
import down_arrow from '../../assets/purple/down-arrow.png';
import arrowRight from '../../assets/RoundArrowRight.png';
import ecllips from '../../assets/Blue/Ellipse 756.svg';
import { API } from '../../types/api';
import moment from 'moment-timezone';
import { Place } from '@mui/icons-material';

const FAQBox = ({ title, content }: { title: string; content: string }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="collapse-div">
      <a onClick={() => setShow(!show)} className="collapsible ">
        <p className="!text-[20px]">{title}</p>
        <img src={down_arrow} alt="" />
      </a>
      {show && (
        <div className="content">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export const Blue = ({
  webinar,
  onRegister,
}: {
  webinar: API.Responses.WebinarDetails;
  onRegister: Function;
}) => {
  const {
    host_name,
    host_bio,
    address,
    host_designation,
    before_discount_amount,
    benefits,
    benefits_title,
    closes_at,
    ends_at,
    excerpt,
    faq_title,
    faqs,
    host_image_url,
    host_image_alt_url,
    keywords,
    name,
    pk,
    points_covered,
    points_covered_title,
    registration_amount,
    starts_at,
    statistics,
    testimonials,
    testimonials_title,
    about_workshop_title,
    about_workshop,
  } = webinar.page;

  const [showCoundown, setShowCountdown] = useState(true);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const endTime = moment(starts_at).toDate().getTime();

  let interval: any = useRef();

  const startCoundown = () => {
    interval = setInterval(() => {
      const now = new Date().getTime();
      const timeLeft = (endTime - now) / 1000;
      setSeconds(Math.floor(timeLeft % 60));
      setMinutes(Math.floor(timeLeft / 60) % 60);
      setHours(Math.floor(timeLeft / (60 * 60)) % 24);
      setDays(Math.floor(timeLeft / (60 * 60 * 24)));
      // console.log('now', now);
      if (timeLeft <= 0) {
        clearInterval(interval.current);
        setSeconds(0);
        setMinutes(0);
        setHours(0);
        setDays(0);
        setShowCountdown(false);
      }
    }, 1000);
  };

  useEffect(() => {
    startCoundown();
    return () => {
      clearInterval(interval.current);
    };
  });

  return (
    <>
      <div className="home-nav sticky top-0 z-1 black-bg w-full">
        <div className="max-width mx-auto nav-inline !font-space !px-2">
          <h2 className="!font-space">{host_name}</h2>

          <div className="countdowns hidden md:flex !font-space">
            <h5 className="!font-space" id="days">
              {days}
            </h5>
            <h6 className="!font-space">Days</h6>
            <img className="dots" src={dots} />
            <h5 className="!font-space" id="hours">
              {hours}
            </h5>
            <h6 className="!font-space">Hours</h6>
            <img className="dots" src={dots} />
            <h5 className="!font-space" id="mins">
              {minutes}
            </h5>
            <h6 className="!font-space">Mins</h6>
            <img className="dots" src={dots} />
            <h5 className="!font-space" id="secs">
              {seconds}
            </h5>
            <h6 className="!font-space">Seconds</h6>
          </div>

          {/* <a
            className="book-btn purple-btn desktop font-space !text-white"
            onClick={() => onRegister()}
          >
            Book your slot for ₹{registration_amount}
          </a> */}
          <button
            onClick={() => onRegister()}
            type="button"
            className="flex desktop items-center text-white bg-[#005AF1] hover:bg-black focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm sm:px-5 px-10 py-2.5 mb-2"
          >
            <span className="text-[18px] sm:mr-4 mr-auto">
              Book your Seats at ₹{registration_amount}
            </span>
            {/* <img src={arrowRight} alt="" /> */}
          </button>
          <a
            className="bg-[#005AF1] register-btn mobile"
            onClick={() => onRegister()}
          >
            Register
          </a>
        </div>
        <div className="countdowns flex md:hidden !px-2">
          <h5 className="!font-space" id="dayss">
            {days}
          </h5>
          <h6 className="!font-space">Days</h6>
          <img className="dots" src={dots} />
          <h5 className="!font-space" id="hourss">
            {hours}
          </h5>
          <h6 className="!font-space">Hours</h6>
          <img className="dots" src={dots} />
          <h5 className="!font-space" id="minss">
            {minutes}
          </h5>
          <h6 className="!font-space">Mins</h6>
          <img className="dots" src={dots} />
          <h5 className="!font-space" id="secss">
            {seconds}
          </h5>
          <h6 className="!font-space">Sec</h6>
        </div>
      </div>

      <div className="home lg:flex lg:px-[150px] lg:py-[100px] w-full text-white p-4 !py-16 sm:px-[3rem] text-left gap-16 BricolageFont centerPage">
        <div className="max-w-[1200px] sm:grid sm:grid-cols-2 gap-4">
          <div className="w-full  flex-col gap-2 gap-lg-4 self-center grid sm:text-left text-center">
            {/* <label className='text-[20px] font-semibold mb-[16px]'>Hosted by <span className='text-[#FF6F31] text-[20px]'>Bigbang.social</span></label> */}
            <h5 className="text-[20px] font-semibold mt-[16px] text-[#6B9AEA]">
              {moment(starts_at).format('ddd')}{' '}
              {moment(starts_at).format('lll')} -{' '}
              {moment(ends_at).format('hh:mm A')} IST
            </h5>
            <h3 className="text-[32px] font-bold mb-[24px] text-left">
              {name}
              {/* by{' '}
                    <span className="text-[#FF6F31] text-[32px]">{host_name}</span> */}
            </h3>
            {address && <p className="text-[16px] mb-4">📍 {address}</p>}
            <p className="text-[16px] mb-4">{excerpt}</p>
            <div
              className="sm:w-fit grid w-full my-[15px] lg:ml-0"
              onClick={() => onRegister()}
            >
              <button
                type="button"
                className="flex items-center text-white bg-[#005AF1] hover:bg-black focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm sm:px-5 px-10 py-2.5 mb-2"
              >
                <span className="text-[18px] sm:mr-4 mr-auto">
                  Book your Seats at ₹{registration_amount}
                </span>
                <img src={arrowRight} alt="" />
              </button>
            </div>
          </div>
          <div className="w-full  justify-center lg:justify-end items-center flex pt-8">
            <div className="max-w-[28rem] rounded-xl p-8 bg-[#515485]">
              <figure className="relative max-w-sm transition-all duration-300 filter">
                <img
                  className="rounded-xl shadow"
                  src={host_image_url}
                  alt=""
                />
                {/* <figcaption className="absolute px-4 text-lg bottom-0 bg-white widthWeb rounded-xl">
                            <div className="p-3 flex justify-between">
                            <div className="">
                                <h6 className="text-[19px] font-semibold">{host_name}</h6>
                                <p className="text-[#666] text-[14px]">
                                {host_designation}{' '}
                                </p>
                            </div>
                            <div className="">
                                <img className="rounded-xl shadow" src={logo} alt="" />
                            </div>
                            </div>
                        </figcaption> */}
              </figure>
            </div>
          </div>
        </div>
      </div>
      {/*About the workshop*/}
      <div className="lg:px-[150px] lg:py-[100px] flex w-full p-4 sm:px-[3rem] !py-16 gap-16 bg-[#0B0B2F] text-white BricolageFont centerPage">
        <div className="w-full]">
          <div className="w-full gap-16 sm:flex">
            <div className="sm:w-1/2 w-full">
              <img
                className="sm:flex hidden rounded-xl shadow w-[500px] object-cover"
                src={host_image_alt_url || host_image_url}
                alt=""
              />
            </div>
            <div className="sm:w-1/2 w-full">
              <h3 className="sm:text-[44px] text-[28px] pb-[28px] font-semibold leading-6 text-left">
                {about_workshop_title || 'About the Workshop'}
              </h3>
              <p className="text-left text-md text-[18px]">{about_workshop}</p>
              <div
                className="sm:w-fit grid w-full my-[15px] lg:ml-0"
                onClick={() => onRegister()}
              >
                <button
                  type="button"
                  className="flex items-center text-white bg-[#005AF1] hover:bg-black focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm sm:px-5 px-10 py-2.5 mb-2"
                >
                  <span className="text-[18px] sm:mr-4 mr-auto">
                    Book your Seats at ₹{registration_amount}
                  </span>
                  <img src={arrowRight} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Point Covered section */}
      <div className="lg:px-[150px] lg:py-[100px] w-full text-white sm:px-[3rem] p-4 !py-16 bg-[#06061A] BricolageFont centerPage">
        <div className="max-w-[1200px] sm:items-center flex flex-col">
          <div className="pb-[28px] text-center">
            <h3 className="sm:text-[44px] text-[28px] font-bold tracking-tight">
              {points_covered_title || 'Why attend this workshop?'}
            </h3>
          </div>
          <div className="sm:grid sm:grid-cols-2 gap-4 text-left">
            {points_covered.map((it) => (
              <div className="sm:mb-0 mb-3 bg-[#1C1C35] rounded-lg border-1 border-[#515485]">
                <div className="p-3">
                  <h6 className="text-[16px] font-semibold tracking-tight ">
                    {it.value.title}
                  </h6>
                  <p className="mt-1">{it.value.body}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="pt-[32px] text-center" onClick={() => onRegister()}>
            <div
              className="sm:w-fit grid w-full my-[15px] lg:ml-0"
              onClick={() => onRegister()}
            >
              <button
                type="button"
                className="flex items-center text-white bg-[#005AF1] hover:bg-black focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm sm:px-5 px-10 py-2.5 mb-2"
              >
                <span className="text-[18px] sm:mr-4 mr-auto">
                  Book your Seats at ₹{registration_amount}
                </span>
                <img src={arrowRight} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Benefit Section */}
      {benefits.length > 0 && (
        <div className="lg:px-[150px] w-full text-white sm:px-[3rem] p-4 !py-16 bg-[#06061A] BricolageFont centerPage">
          <div className="w-full bg-[#0D1053] rounded-lg p-8">
            <div className="pb-[24px]">
              <h3 className="sm:text-[44px] text-[32px] font-semibold tracking-tight text-white">
                {benefits_title || 'What will you learn from this workshop?'}
              </h3>
            </div>
            <div className="sm:grid sm:grid-cols-2 gap-x-6 gap-y-4 text-left mt-10">
              {benefits.map((benefit) => (
                <div className="flex mb-[10px]">
                  {/* <h6 className="mb-2 font-large font-bold tracking-tight ">{title}</h6> */}
                  {/* <img src={CheckCircle} alt="" className="mr-3 h-[18px] mt-[4px]" /> */}
                  <p className="font-normal text-white">
                    {benefit.value.title}
                  </p>
                  <p className="text-sm text-gray-500">
                    {benefit.value.body && `: ${benefit.value.body}`}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/*Meet the Mentor section*/}
      <div className="lg:px-[150px] lg:py-[100px] w-full bg-[#06061A] text-white sm:px-[3rem] p-4 !py-16 gap-16 BricolageFont centerPage">
        <div className="max-w-[1200px]">
          <h3 className="sm:hidden flex text-[28px]  pb-[24px] font-semibold leading-6 text-left">
            Meet the creator
          </h3>
          <div className="w-full sm:flex gap-8">
            <div className="sm:w-1/2 w-full">
              <img
                className="sm:flex hidden rounded-xl shadow h-[500px] w-[500px] object-cover"
                src={host_image_url}
                alt=""
              />
            </div>
            <div className="sm:w-1/2 w-full sm:pt-0 pt-4">
              <h3 className="sm:flex hidden text-[44px] pb-[24px] font-semibold leading-6 text-left">
                Meet the mentor
              </h3>
              <div className="flex justify-center">
                <img
                  className="sm:hidden flex rounded-xl shadow"
                  src={host_image_url}
                  alt=""
                />
              </div>

              <p className="pb-[16px] mt-4">{host_bio}</p>
            </div>
          </div>

          <div className="w-full sm:pt-[80px] pt-[40px]">
            <div className="hidden sm:grid sm:grid-cols-4 grid-cols-2 gap-4">
              {statistics.map((stat, index) => (
                <div
                  className={`creatorCard ${
                    index < statistics.length - 1
                      ? 'border-r border-[#444]'
                      : ''
                  }`}
                >
                  <h3 className="text-[24px] font-semibold">
                    {stat.value.value}
                  </h3>
                  <p className="">{stat.value.title}</p>
                </div>
              ))}
            </div>
            <div className="sm:hidden grid grid-cols-1 gap-4">
              <div className="grid grid-cols-2 gap-4">
                {statistics.map((stat, index) => (
                  <div
                    className={`creatorCard ${
                      index < statistics.length ? 'border-b border-[#444]' : ''
                    }`}
                  >
                    <h3 className="text-[24px] font-semibold">
                      {stat.value.value}
                    </h3>
                    <p className="">{stat.value.title}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {testimonials && testimonials.length > 0 ? (
        <div className="testimonials lg:px-[150px] lg:py-[100px] w-full bg-[#06061A] text-white sm:px-[3rem] p-4 !py-16 gap-16 BricolageFont centerPage">
          <div className="w-full flex flex-col gap-8">
            <h3 className="flex text-[44px] justify-center pb-[24px] font-semibold leading-6 text-left">
              {testimonials_title || 'Testimonials'}
            </h3>
            <div className="w-full sm:flex flex flex-col gap-8">
              <div className="w-full cards tab-card">
                <div className="cards-div sm:flex-row flex-col gap-8">
                  {testimonials.map((testimonial) => (
                    <div className="card-div">
                      <h6>{testimonial.value.text}</h6>

                      <div className="card-footer">
                        {testimonial.value.image ? (
                          <img
                            src={testimonial.value.image}
                            alt=""
                            className="h-[43px]"
                          />
                        ) : (
                          <></>
                        )}
                        <div className="card-foo">
                          <h4>{testimonial.value.author}</h4>
                          {/* <p>{testimonial.value.designation}</p> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {faqs && faqs.length > 0 ? (
        <div className="faq lg:px-[150px] lg:py-[100px] w-full bg-[#0B0B2F] text-white sm:px-[3rem] p-4 !py-16 gap-16 BricolageFont centerPage">
          <div className="w-full">
            <div className="flex flex-col gap-8">
              <div className="w-full flex justify-center">
                <h1 className="">FAQs</h1>
                {/* <h1 className="!font-space">
                  Frequently Asked Questions, Answered.
                </h1> */}
              </div>

              <div className="w-full">
                <div className="text-div !flex flex-col w-full">
                  {faqs.map((faq) => (
                    <FAQBox
                      title={faq.value.question}
                      content={faq.value.answer}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="hurry w-full">
        <div className="max-width mx-auto px-4">
          <div className="head-div !flex flex-col md:!flex-row">
            <div>
              <h5 className="purple-text">MAKE HURRY</h5>
              <h1 className="!font-space">
                Time is running out, Book your spot fast
              </h1>
            </div>
            {/* <a className="get-btn purple-btn" onClick={() => onRegister()}>
              Register at <span>₹{before_discount_amount}</span> ₹
              {registration_amount}
            </a> */}
          </div>
          <div className="counter hidden md:flex flex-row">
            <div className="time">
              <h4 id="Days">{days} Days</h4>
            </div>
            <div className="time">
              <h4 id="Hours">{hours} Hours</h4>
            </div>

            <div className="time">
              <h4 id="Mins">{minutes} Mins</h4>
            </div>
            <div className="time">
              <h4 id="Secs">{seconds} Seconds</h4>
            </div>
          </div>

          <div className="flex md:hidden counter d-flex d-sm-none ">
            <div className="time ">
              <h4 id="Dayss">{days}</h4>
              <h4 className="!text-[#a9a9a9]">Days</h4>
            </div>
            <div className="time ">
              <h4 id="Hourss">{hours}</h4>
              <h4 className="!text-[#a9a9a9]">Hours</h4>
            </div>
          </div>
          <div className="flex md:hidden counter d-flex d-sm-none ">
            <div className="time ">
              <h4 id="Minss">{minutes}</h4>
              <h4 className="!text-[#a9a9a9]">Mins</h4>
            </div>
            <div className="time ">
              <h4 id="Secss">{seconds}</h4>
              <h4 className="!text-[#a9a9a9]">Sec</h4>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="footer w-full">
        <div className="max-width mx-auto">
          <div className="head-div">
            <h1>MOSES KAUL</h1>
            <div className="socials">
              <a href="">Twitter</a>
              <a href="">LinkedIn</a>
              <a href="">Instagram</a>
            </div>
          </div>

          <div className="foot-div">
            <div className="tab-links">
              <a href="">Help</a>
              <a href="">Privacy</a>
            </div>
            <p>© 2077 Highsocial</p>
          </div>
        </div>
      </div> */}
    </>
  );
};
