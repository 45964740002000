import React from 'react';
import map from '../../assets/map.svg';
import calendar from '../../assets/calendar1.svg';
import './AboutWorkshop.css';
import { Link } from 'react-router-dom';
import { API } from '../../types/api';
import moment from 'moment-timezone';

const AboutWorkshop = ({
  webinar,
  onRegister,
}: {
  webinar: API.Responses.WebinarDetails;
  onRegister: Function;
}) => {
  const {
    about_workshop_title,
    about_workshop,
    starts_at,
    ends_at,
    registration_amount,
  } = webinar.page;

  return (
    <div className="lg:px-[150px] lg:py-[100px] flex w-full p-4 sm:px-[3rem] !py-16 gap-16 bg-[#FF6F31] text-white BricolageFont centerPage">
      <div className="max-w-[1200px]">
        <h3 className="sm:text-[44px] text-[28px] pb-[28px] font-semibold leading-6 text-left">
          {about_workshop_title || 'About the Workshop'}
        </h3>
        <div className="w-full gap-16 sm:flex">
          <div className="sm:w-2/3 w-full">
            <p className="text-left text-md text-[18px]">{about_workshop}</p>
            <div
              className="sm:w-fit grid w-full my-[15px] lg:ml-0 mt-5"
              onClick={() => onRegister()}
            >
              <button
                type="button"
                className="text-[#1F1F1F] text-[18px] bg-white hover:bg-black hover:text-white focus:ring-4 focus:ring-blue-300 font-semibold rounded-lg px-5 py-2.5 mr-2 sm:mb-2 mb-[24px] p-[1.25rem]"
              >
                Book your Seats at ₹{registration_amount}
              </button>
            </div>
          </div>
          <div className="sm:w-1/3 w-full">
            <div className="grid grid-cols-1 gap-2">
              <div className="aboutCard flex gap-4 items-center">
                <img src={calendar} className="h-[20px]" />
                <div className="flex flex-col">
                  <p className="text-[18px]">
                    {moment(starts_at).format('ddd, MMM DD, YYYY')}
                  </p>
                  <p className="text-[14px]">
                    {moment(starts_at).format('hh:mm A')} -{' '}
                    {moment(ends_at).format('hh:mm A')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutWorkshop;
