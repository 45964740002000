import React, { useState } from 'react';
import './home.css';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../components/Common/Footer';
import { Helmet } from 'react-helmet';
import { API } from '../types/api';
import { RegisterModal } from '../components/Modals/Register';
import { BlueV2 } from '../components/WorkshopBlueV2/BlueV2';

export const BlueV2Template = ({
  page,
}: {
  page: API.Responses.WebinarDetails;
}) => {
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);

  const onRegister = () => {
    setRegisterModalOpen(true);
  };

  const { name } = page.page;

  return (
    <div className="w-full text-black bg-white centerPage">
      <Helmet>
        <title>{name}</title>
        <meta name="description" content={name} />
      </Helmet>

      <BlueV2 webinar={page} onRegister={onRegister} />
      <Footer />
      <RegisterModal
        show={registerModalOpen}
        setShow={setRegisterModalOpen}
        webinar={page}
      />
    </div>
  );
};
