import React from 'react'
import { API } from '../../types/api'
import moment from 'moment-timezone';

import { Navbar } from './Navbar'
import { Hero } from './Hero'
import { Benefits } from './Benefits'
import { Reason } from './Reason'
import { Creator } from './Creator'
import { Testimonials } from './Testimonials'
import { Features } from './Features'
import { FAQ } from './FAQ'

export const YellowTemplate = ({
  page,
  onRegister
}: {
  page: API.Responses.WebinarDetails;
  onRegister: Function
}) => {

  const {
    host_name,
    host_bio,
    host_designation,
    before_discount_amount,
    benefits,
    benefits_title,
    closes_at,
    ends_at,
    excerpt,
    faq_title,
    faqs,
    host_image_url,
    keywords,
    name,
    pk,
    points_covered,
    points_covered_title,
    registration_amount,
    starts_at,
    statistics,
    testimonials,
    testimonials_title,
  } = page.page;

  
  return (
    <>
    <Navbar name={host_name} starts_at={starts_at} discount={before_discount_amount} register={registration_amount} onRegister={onRegister} />
    <Hero starts_at={starts_at} ends_at={ends_at} discount={before_discount_amount} register={registration_amount} name={name} excerpt={excerpt} host_name={host_name} designation={host_designation} image={host_image_url} keywords={keywords} onRegister={onRegister} />
    {
      benefits.length > 0 && <Benefits title={benefits_title} benefits={benefits} />
    }
    {
      points_covered.length > 0 && <Reason title={points_covered_title} points={points_covered} />
    }
    <Creator image={host_image_url} name={host_name} bio={host_bio} statistics={statistics} />
    {
      testimonials.length > 0 && <Testimonials title={testimonials_title} items={testimonials} />
    }
    <Features starts_at={starts_at} ends_at={ends_at} discount={before_discount_amount} register={registration_amount} onRegister={onRegister} />
    {
      faqs.length > 0 && <FAQ title={faq_title} faqs={faqs} />
    }
    
    </>
  )
}
