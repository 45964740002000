import { CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../services/api';
import { useEffect, useState } from 'react';
import { API } from '../types/api';
import { OrangeTemplate } from './OrangeTemplate';
import { PurpleTemplate } from './PurpleTemplate';
import { BlueTemplate } from './BlueTemplate';
import { Yellow } from './Yellow';
import { BlueV2Template } from './BlueV2Template';

export const WebinarLoader = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState<API.Responses.WebinarDetails>();

  const getWebinarDetails = async () => {
    const response = await api.Webinar.getWebinarDetails(slug);
    if ('error' in response) {
      return navigate('/404');
    }

    window.localStorage.setItem('slug', slug);

    setPage(response);
    console.log(response);
  };

  useEffect(() => {
    getWebinarDetails();
  }, []);

  if (!page) {
    return (
      <div className="w-screen h-screen flex justify-center items-center text-orange-500">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <>
      {page.page.template_choosen === 'orange' ? (
        <OrangeTemplate page={page} />
      ) : page.page.template_choosen === 'black' ? (
        <PurpleTemplate page={page} />
      ) : page.page.template_choosen === 'blue' ? (
        <BlueTemplate page={page} />
      ) : page.page.template_choosen === 'blueV2' ? (
        <BlueV2Template page={page} />
      ) : page.page.template_choosen === 'yellow' ? (
        <Yellow page={page} />
      ) : (
        <div className="w-screen h-screen flex justify-center items-center text-orange-500">
          <CircularProgress color="inherit" />
        </div>
      )}
    </>
  );
};
