import React, {useState, useRef, useEffect} from 'react'
import { API } from '../../types/api'
import moment from 'moment-timezone'

export const Features = (
    {
        starts_at,
        ends_at,
        discount,
        register,
        onRegister
    }: {
        starts_at: string,
        ends_at: string,
        discount: number,
        register: number,
        onRegister: Function
    }
)  => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const endTime = moment(starts_at).toDate().getTime();
    let interval: any = useRef();

    const startCoundown = () => {
        interval = setInterval(() => {
          const now = new Date().getTime();
          const timeLeft = (endTime - now) / 1000;
          setSeconds(Math.floor(timeLeft % 60));
          setMinutes(Math.floor(timeLeft / 60) % 60);
          setHours(Math.floor(timeLeft / (60 * 60)) % 24);
          setDays(Math.floor(timeLeft / (60 * 60 * 24)));
          // console.log('now', now);
          if (timeLeft <= 0) {
            clearInterval(interval.current);
            setSeconds(0);
            setMinutes(0);
            setHours(0);
            setDays(0);
          }
        }, 1000);
      };
    
      useEffect(() => {
        startCoundown();
        return () => {
          clearInterval(interval.current);
        };
      });
  return (
    
    <div className='w-full bg-[#1F1F1F] py-24 px-5 md:px-32 pb-40 hidden md:flex flex-col text-white relative'>
        <div className='flex items-center w-full justify-between'>

            <div className='flex flex-col'>
                <p className='uppercase font-instrument text-[#FFF127]'>Time left</p>

                <p className='uppercase font-bebas mt-2 text-6xl'>Time is running out, Book your spot fast</p>
            </div>

            <div className=''>
                <button onClick={() => {onRegister()}} className='bg-[#FFF127] px-4 py-3 text-black font-grot font-semibold'>
                Book your Slot for <span className='line-through'>₹{discount}</span> ₹{register}
                </button>
            </div>

        </div>

        <div className='w-full flex mt-10'>
            <div className='w-[250px] bg-[#262626] shadow-[6px_6px_0px_0px_#333] border border-white/40 p-8 flex flex-col relative overflow-hidden mr-5 mb-5'>
                <p className='text-6xl font-bebas z-10'>{days}</p>
                <p className='font-grot text-lg mt-2 z-10'>Days</p>
                <div className='absolute w-40 h-40 bg-white/10 blur-xl rounded-full -top-16 -left-16'></div>
            </div>
            
            <div className='w-[250px] bg-[#262626] shadow-[6px_6px_0px_0px_#333] border border-white/40 p-8 flex flex-col relative overflow-hidden mr-5 mb-5'>
                <p className='text-6xl font-bebas z-10'>{hours}</p>
                <p className='font-grot text-lg mt-2 z-10'>Hours</p>
                <div className='absolute w-40 h-40 bg-white/10 blur-xl rounded-full -top-16 -left-16'></div>
            </div>
            
            <div className='w-[250px] bg-[#262626] shadow-[6px_6px_0px_0px_#333] border border-white/40 p-8 flex flex-col relative overflow-hidden mr-5 mb-5'>
                <p className='text-6xl font-bebas z-10'>{minutes}</p>
                <p className='font-grot text-lg mt-2 z-10'>Mins</p>
                <div className='absolute w-40 h-40 bg-white/10 blur-xl rounded-full -top-16 -left-16'></div>
            </div>
            
            <div className='w-[250px] bg-[#262626] shadow-[6px_6px_0px_0px_#333] border border-white/40 p-8 flex flex-col relative overflow-hidden mr-5 mb-5'>
                <p className='text-6xl font-bebas z-10'>{seconds}</p>
                <p className='font-grot text-lg mt-2 z-10'>Seconds</p>
                <div className='absolute w-40 h-40 bg-white/10 blur-xl rounded-full -top-16 -left-16'></div>
            </div>
        </div>
        
    </div>
  )
}
