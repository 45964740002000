import React, {useState} from 'react'
import { API } from '../../types/api';

import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const FAQContent = ({question, answer}:{question: string, answer: string}) => {
    const matches = useMediaQuery('(min-width:768px)');
    const [show, setShow] = useState(false)

    if (matches) {
        return (
            <div className='flex flex-col w-[48%] mb-10 pb-5 border-b border-[#454545]'>
                <p className='text-xl font-grot'>{question}</p>
                
                <p className='text-[#808080] mt-2 font-grot'>{answer}</p>
            </div>
        )
    } else {
        return (
            <div className='w-full flex flex-col pb-5 border-b border-[#454545] mb-5'>
                <div onClick={() => setShow(!show)} className='flex items-center justify-between'>
                    <p className='text-xl font-grot pr-5'>{question}</p>
                    <KeyboardArrowDownIcon fontSize='large' sx={{color:'#fff'}} />
                </div>
                {
                    show &&
                    <p className='text-[#808080] mt-2 font-grot'>{answer}</p>
                }
                
            </div>
        )
    }
    
}

export const FAQ = (
    {
        title,
        faqs
    }:
    {
        title: string,
        faqs: API.Responses.WebinarDetails['page']['faqs']
    }
    ) => {
  return (
    <div id='yellowFAQ' className='w-full bg-[#2B2B2B] px-5 md:px-32 py-24 pb-40 flex flex-col text-white relative'>

        <div className='flex flex-col'>
            <p className='uppercase font-instrument text-[#FFF127]'>FAQ</p>

            <p className='uppercase font-bebas mt-2 text-4xl md:text-6xl'>{title}</p>
        </div>

        <div className='w-full flex justify-between flex-wrap mt-16'>
            {
                faqs.map((i) => {
                    return (
                        <FAQContent question={i.value.question} answer={i.value.answer} />
                    )
                })
            }

        </div>

    </div>
  )
}
