import React from 'react'
import { API } from '../../types/api';

import bg from '../../assets/benefits-bg.png'
import DoneIcon from '@mui/icons-material/Done';

export const Benefits = (
    {
        title,
        benefits
    }:
    {
        title: string,
        benefits: API.Responses.WebinarDetails['page']['benefits']
    }
) => {
  return (
    <div className='w-full px-5 md:px-10 bg-[#FFF127] py-24 md:pb-40 flex flex-col items-center text-black relative'>
        <p className='uppercase font-instrument'>Benefits</p>

        <p className='uppercase font-bebas mt-2 text-4xl md:text-6xl'>{title}</p>

        <div className='px-5 md:px-10 flex justify-center flex-wrap w-full mt-10'>
            {
                benefits.map((benefit) => {
                    return (
                        <div className='flex items-center mb-5 mr-4 md:mr-8'>
                            <DoneIcon fontSize='small' />
                            <p className='text-lg font-instrument ml-2 font-medium'>{benefit.value.title}</p>
                        </div>
                    )
                })
            }
            
            
        </div>

        <img className='w-full absolute bottom-0' src={bg} />
    </div>
  )
}
