import React, { useState } from 'react';
import './home.css';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../components/WorkshopOrange/Header';
import Footer from '../components/WorkshopOrange/Footer';
import { Helmet } from 'react-helmet';
import { OrangeWorkshop } from '../components/WorkshopOrange/Workshop';
import { API } from '../types/api';
import { RegisterModal } from '../components/Modals/Register';

export const OrangeTemplate = ({
  page,
}: {
  page: API.Responses.WebinarDetails;
}) => {
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);

  const onRegister = () => {
    setRegisterModalOpen(true);
  };

  return (
    <div className="w-full text-black bg-white centerPage">
      <Helmet>
        <title>{page.page.name}</title>
        <meta name="description" content={page.page.name} />
      </Helmet>
      <Header
        pageTitle={page.page.host_name}
        countdownTime={page.page.starts_at.toString()}
        onRegisterClick={onRegister}
      />
      <OrangeWorkshop webinar={page} onRegister={onRegister} />
      <Footer />
      <RegisterModal
        show={registerModalOpen}
        setShow={setRegisterModalOpen}
        webinar={page}
      />
    </div>
  );
};
