import React, {useState, useRef, useEffect} from 'react'
import { API } from '../../types/api';
import moment from 'moment-timezone';
import { useMediaQuery } from '@mui/material';

export const Navbar = (
    {
        name,
        starts_at,
        discount,
        register,
        onRegister
    }: {
        name: string,
        starts_at: string,
        discount: number,
        register: number,
        onRegister: Function
    }
) => {
    const matches = useMediaQuery('(min-width:768px)');
    const endTime = moment(starts_at).toDate().getTime();

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    let interval: any = useRef();

    const startCoundown = () => {
        interval = setInterval(() => {
          const now = new Date().getTime();
          const timeLeft = (endTime - now) / 1000;
          setSeconds(Math.floor(timeLeft % 60));
          setMinutes(Math.floor(timeLeft / 60) % 60);
          setHours(Math.floor(timeLeft / (60 * 60)) % 24);
          setDays(Math.floor(timeLeft / (60 * 60 * 24)));
          // console.log('now', now);
          if (timeLeft <= 0) {
            clearInterval(interval.current);
            setSeconds(0);
            setMinutes(0);
            setHours(0);
            setDays(0);
            // setShowCountdown(false);
          }
        }, 1000);
      };
    
      useEffect(() => {
        startCoundown();
        return () => {
          clearInterval(interval.current);
        };
      });
  return (
    <div className='flex flex-col w-full font-bebas fixed z-50'>
        <div className='flex items-center w-full justify-between md:justify-around bg-[#1F1F1F] py-5 px-4'>
            <div>
                <p className='uppercase font-bebas font-medium text-white text-3xl'>{name}</p>
            </div>

            <div className='hidden md:flex items-center font-medium '>

                <div className='flex items-center mx-1'>
                    <p className='text-2xl'>{days} <span className='text-[#848484] ml-1 uppercasetext-2xl'>days</span> <span className='text-[#363636] ml-1text-2xl'>/</span></p>
                </div>
                <div className='flex items-center mx-1'>
                    <p className='text-2xl'>{hours} <span className='text-[#848484] ml-1 uppercasetext-2xl'>hours</span> <span className='text-[#363636] ml-1text-2xl'>/</span></p>
                </div>
                <div className='flex items-center mx-1'>
                    <p className='text-2xl'>{minutes} <span className='text-[#848484] ml-1 uppercasetext-2xl'>mins</span> <span className='text-[#363636] ml-1text-2xl'>/</span></p>
                </div>
                <div className='flex items-center mx-1'>
                    <p className='text-2xl'>{seconds} <span className='text-[#848484] ml-1 uppercasetext-2xl'>seconds</span></p>
                </div>

            </div>

            <div>
                {
                    matches ?
                    <button onClick={() => {onRegister()}} className='bg-[#FFF127] text-black px-4 py-2 font-semibold font-grot'>
                        Book your Slot for <span className='line-through'>₹{discount}</span> ₹{register}
                    </button>
                    :
                    <button onClick={() => {onRegister()}} className='bg-[#FFF127] text-black px-4 py-2 font-semibold font-grot'>
                        Register
                    </button>
                }
                
            </div>
        </div>
        <div className='w-full flex md:hidden items-center justify-center py-2 px-2 bg-[#323232]'>
            <div className='flex items-center font-medium '>

                <div className='flex items-center mx-1'>
                    <p className=' text-xl md:text-2xl'>{days} <span className='text-[#848484] ml-1 uppercase text-xl md:text-2xl'>d</span> <span className='text-[#464646] ml-1 text-xl md:text-2xl'>/</span></p>
                </div>
                <div className='flex items-center mx-1'>
                    <p className=' text-xl md:text-2xl'>{hours} <span className='text-[#848484] ml-1 uppercase text-xl md:text-2xl'>h</span> <span className='text-[#464646] ml-1 text-xl md:text-2xl'>/</span></p>
                </div>
                <div className='flex items-center mx-1'>
                    <p className=' text-xl md:text-2xl'>{minutes} <span className='text-[#848484] ml-1 uppercase text-xl md:text-2xl'>m</span> <span className='text-[#464646] ml-1 text-xl md:text-2xl'>/</span></p>
                </div>
                <div className='flex items-center mx-1'>
                    <p className=' text-xl md:text-2xl'>{seconds} <span className='text-[#848484] ml-1 uppercase text-xl md:text-2xl'>s</span></p>
                </div>

            </div>
        </div>
    </div>
  )
}
