import { AxiosRequestConfig } from 'axios';
import FormData from 'form-data';

export class RequestGenerators {
  static getWebinarDetails = (slug: string): AxiosRequestConfig => {
    return {
      method: 'GET',
      url: '/webinar/getRegistrationPageBySlug',
      params: {
        slug,
      },
    };
  };

  static registerWebinar = (
    webinarId: number,
    {
      name,
      phone,
      email,
      city,
      state,
      age,
      type,
      organization,
      jobProfile,
      designation,
      institute,
      course,
    }: {
      name: string;
      phone: string;
      email: string;
      city: string;
      state: string;
      age: number;
      type?: 'employed' | 'student';
      organization?: string;
      jobProfile?: string;
      designation?: string;
      institute?: string;
      course?: string;
    }
  ): AxiosRequestConfig => {
    const data = new FormData();

    data.append('webinar_id', webinarId);
    data.append('name', name);
    data.append('phone', phone);
    data.append('email', email);
    data.append('city', city);
    data.append('state', state);
    data.append('age', age);

    data.append('type', type || 'NA');

    if (type === 'employed') {
      if (organization) {
        data.append('organization', organization);
      }
      if (jobProfile) {
        data.append('job_profile', jobProfile);
      }
      if (designation) {
        data.append('designation', designation);
      }
    } else {
      if (institute) {
        data.append('institute', institute);
      }
      if (course) {
        data.append('course', course);
      }
    }

    return {
      method: 'POST',
      url: '/webinar/rest_register',
      data,
    };
  };

  static verifyPayment = ({
    orderId,
    paymentId,
    signature,
  }: {
    orderId: string;
    paymentId: string;
    signature: string;
  }): AxiosRequestConfig => {
    const data = new FormData();

    data.append('order_id', orderId);
    data.append('payment_id', paymentId);
    data.append('signature', signature);

    return {
      method: 'POST',
      url: '/webinar/rest_verify_order',
      data,
    };
  };

  static registerWebinarV2 = (
    webinarId: number,
    {
      name,
      phone,
      email,
      state,
      couponCode,
    }: {
      name: string;
      phone: string;
      email: string;
      state: string;
      couponCode?: string;
    }
  ): AxiosRequestConfig => {
    const data = new FormData();

    data.append('webinar_id', webinarId);
    data.append('name', name);
    data.append('phone', phone);
    data.append('email', email);
    data.append('state', state);

    if (couponCode) {
      data.append('coupon_code', couponCode.toLowerCase());
    }

    return {
      method: 'POST',
      url: '/webinar/initiate_registration',
      data,
    };
  };

  static checkCouponCode = (
    webinarId: number,
    couponCode: string
  ): AxiosRequestConfig => {
    return {
      method: 'GET',
      url: '/webinar/rest_checkCouponCode',
      params: {
        webinar_id: webinarId,
        coupon_code: couponCode.toLowerCase(),
      },
    };
  };

  static verifyPaymentV2 = ({
    orderId,
    paymentId,
    signature,
  }: {
    orderId: string;
    paymentId: string;
    signature: string;
  }): AxiosRequestConfig => {
    const data = new FormData();

    data.append('order_id', orderId);
    data.append('payment_id', paymentId);
    data.append('signature', signature);

    return {
      method: 'POST',
      url: '/webinar/verify_order_v2',
      data,
    };
  };
}
