import { AxiosError, AxiosInstance } from 'axios';
import { backendClient } from './api/http-client';

export interface InternalError {
  code: number;
  error: {
    code: string;
    message: string;
  };
}

export const errorConstructor = (
  status?: number,
  code?: string,
  message?: string
): InternalError => {
  return {
    code: status || 400,
    error: {
      code: code || 'EC-000',
      message: message || 'Something went wrong',
    },
  };
};

export const unhandledError = errorConstructor();

export const authError = errorConstructor(401, 'EC-AUTH-001', 'Unauthorized');

export const serverError = errorConstructor(500);

const getErrorCount = () => {
  return Number(window.localStorage.getItem('authErrorCount')) || 0;
};

export const setErrorCount = (count: number) => {
  return window.localStorage.setItem('authErrorCount', count.toString());
};

export const handleAxiosError = (
  err: any,
  client: AxiosInstance = backendClient
) => {
  console.log(`[API] err: get user`);
  console.error(err);
  if (err instanceof AxiosError) {
    const error = err.response?.data;
    console.log({ error });

    const constructedError = errorConstructor(
      err.response?.status,
      error?.['code'],
      error?.['message']
    );

    console.log({ constructedError });

    return constructedError;
  }

  return serverError;
};
