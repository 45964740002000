import React from 'react'
import moment from 'moment-timezone';
import Marquee from "react-fast-marquee";

import img from '../../assets/home-img.png'
import star from '../../assets/star.svg'

export const Hero = (
    {
        starts_at,
        ends_at,
        discount,
        register,
        name,
        excerpt,
        host_name,
        designation,
        image,
        keywords,
        onRegister
    }: {
        starts_at: string,
        ends_at: string,
        discount: number,
        register: number,
        name: string,
        excerpt: string,
        host_name: string,
        designation: string,
        image: string,
        keywords: string,
        onRegister: Function
    }
) => {
  return (
    <div id="yellowHero" className='w-full flex flex-col md:flex-row min-h-screen items-center pt-40 py-20'>
        <div className='w-full md:w-1/2 flex flex-col items-center px-5 md:px-32'>
            <div className='flex flex-col'>
                <p className='font-instrument text-[#FFF127] uppercase text-lg text-center md:text-left'>
                {moment(starts_at).format('ddd')}{' '}
                {moment(starts_at).format('lll')} -{' '}
                {moment(ends_at).format('hh:mm A')} IST
                </p>

                <p className='text-4xl text-center md:text-left md:text-6xl font-bebas mt-5'>{name}</p>

                <p className='mt-5 text-center md:text-left font-instrument text-lg'>{excerpt}</p>

            </div>

            <div className='mt-10 flex md:self-start'>
                <button onClick={() => {onRegister()}} className='bg-[#FFF127] px-4 py-2 text-black font-grot font-semibold'>
                    Book your Slot for <span className='line-through'>₹{discount}</span> ₹{register}
                </button>
            </div>
            
        </div>

        <div className='w-full md:w-1/2 flex items-center flex-col px-5 md:px-32 mt-10 md:mt-0'>

            <div className='font-league w-full'>
                <div className='flex w-full relative'>
                    <div className='w-full absolute top-0 flex flex-col p-5 bg-gradient-to-b from-black/20 to-black/0'>
                        <p className='font-semibold uppercase text-2xl'>{host_name}</p>
                        <p className='text-lg mt-1 opacity-70'>{designation}</p>
                    </div>

                    <img className='w-full md:w-[500px] h-[400px] md:h-[500px] object-cover' src={image} />

                </div>

                {keywords && keywords.length ? (

                    <div className='w-full md:w-[500px] py-2 bg-[#363636]'>
                    <Marquee>
                        {
                            keywords.split(',').map((k) => {
                                return (
                                    <div className='flex items-center px-2'>
                                        <p className='font-league uppercase mr-2.5'>k</p>

                                        <img className='pb-1' src={star} />
                                    </div>
                                )
                            })
                        }
                        
                    </Marquee>
                    </div>
                ) : (
                    <></>
                )}
            </div>

        </div>
    </div>
  )
}
