import { fbPixelConfig } from '../config/fb-pixel';
import { API } from '../types/api';
import api from './api';
import ReactPixel from 'react-facebook-pixel';

const paymentHandler = async (
  e: any,
  orderId: string,
  prefill: {
    name: string;
    email: string;
    contact: string;
  }
) => {
  ReactPixel.trackCustom('payment-initiated', {
    siteIdentifier: fbPixelConfig.siteIdentifier,
    orderId,
  });

  e.preventDefault();
  const options = {
    key: process.env.RAZOR_PAY_KEY_ID,
    name: 'BigBang Academy Webinars',
    description: 'BigBang Academy Webinars',
    order_id: orderId,
    prefill,
    handler: async (response: {
      razorpay_order_id: string;
      razorpay_payment_id: string;
      razorpay_signature: string;
    }) => {
      try {
        const { razorpay_order_id, razorpay_payment_id, razorpay_signature } =
          response;
        console.log({ response });

        const data = {
          orderId: razorpay_order_id,
          paymentId: razorpay_payment_id,
          signature: razorpay_signature,
        };

        ReactPixel.trackCustom('pg-payment-callback', {
          siteIdentifier: fbPixelConfig.siteIdentifier,
          orderId,
          data,
        });

        window.localStorage.setItem('orderId', orderId);

        try {
          // const { success, message, registration } = await api.Webinar.verify(
          //   data
          // );
          // if (registration) {
          //   ReactPixel.trackCustom('pg-payment-success', {
          //     siteIdentifier: fbPixelConfig.siteIdentifier,
          //     orderId,
          //   });
          //   const { attendee_email } = registration;
          //   window.localStorage.setItem('email', attendee_email);
          // }
          window.location.href = '/success';
        } catch (error) {
          ReactPixel.trackCustom('pg-payment-error', {
            siteIdentifier: fbPixelConfig.siteIdentifier,
            orderId,
            error,
          });
          throw error;
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    theme: {
      color: '#686CFD',
    },
  };
  // @ts-ignore
  const rzp1 = new window.Razorpay(options);
  rzp1.open();
};

const paymentHandlerV2 = async (
  e: any,
  orderId: string,
  prefill: {
    name: string;
    email: string;
    contact: string;
  },
  webinar: API.Responses.WebinarDetails
) => {
  ReactPixel.trackCustom('payment-initiated', {
    siteIdentifier: webinar.page.pk,
    name: webinar.page.name,
    orderId,
  });

  e.preventDefault();
  const options = {
    key: process.env.RAZOR_PAY_KEY_ID,
    name: 'BigBang Academy Webinars',
    description: 'BigBang Academy Webinars',
    order_id: orderId,
    prefill,
    handler: async (response: {
      razorpay_order_id: string;
      razorpay_payment_id: string;
      razorpay_signature: string;
    }) => {
      try {
        const { razorpay_order_id, razorpay_payment_id, razorpay_signature } =
          response;
        console.log({ response });

        const data = {
          orderId: razorpay_order_id,
          paymentId: razorpay_payment_id,
          signature: razorpay_signature,
        };

        ReactPixel.trackCustom('pg-payment-callback', {
          siteIdentifier: webinar.page.pk,
          name: webinar.page.name,
          orderId,
          data,
        });

        window.localStorage.setItem('orderId', orderId);

        try {
          const { success, message, registration } = await api.Webinar.verifyV2(
            data
          );
          if (registration) {
            ReactPixel.trackCustom('pg-payment-success', {
              siteIdentifier: webinar.page.pk,
              name: webinar.page.name,
              orderId,
            });
          }
          window.localStorage.setItem('email', prefill.email);
          window.location.href = '/success';
        } catch (error) {
          ReactPixel.trackCustom('pg-payment-error', {
            siteIdentifier: webinar.page.pk,
            name: webinar.page.name,
            orderId,
            error,
          });
          throw error;
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    theme: {
      color: '#686CFD',
    },
  };
  // @ts-ignore
  const rzp1 = new window.Razorpay(options);
  rzp1.open();
};

export default {
  paymentHandler,
  paymentHandlerV2,
};
