import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './blueV2.css';

import dots from '../../assets/purple/dots.png';
import down_arrow from '../../assets/purple/down-arrow.png';
import arrowRight from '../../assets/RoundArrowRight.png';
import map from '../../assets/MapWhite.svg';
import ecllips from '../../assets/Blue/Ellipse 756.svg';
import { API } from '../../types/api';
import moment from 'moment-timezone';
import { Place } from '@mui/icons-material';
import calendar from '../../assets/calendar1.svg';
import heroImage from '../../assets/blueV2-hero.png';

const FAQBox = ({ title, content }: { title: string; content: string }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="collapse-div">
      <a onClick={() => setShow(!show)} className="collapsible ">
        <p className="!text-[20px]">{title}</p>
        <img src={down_arrow} alt="" />
      </a>
      {show && (
        <div className="content">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export const BlueV2 = ({
  webinar,
  onRegister,
}: {
  webinar: API.Responses.WebinarDetails;
  onRegister: Function;
}) => {
  const {
    host_name,
    host_bio,
    address,
    isOnline,
    host_designation,
    before_discount_amount,
    benefits,
    benefits_title,
    closes_at,
    ends_at,
    excerpt,
    faq_title,
    faqs,
    host_image_url,
    host_image_alt_url,
    coHosts,
    keywords,
    name,
    pk,
    points_covered,
    points_covered_title,
    registration_amount,
    starts_at,
    statistics,
    testimonials,
    testimonials_title,
    about_workshop_title,
    about_workshop,
  } = webinar.page;

  // const [showCoundown, setShowCountdown] = useState(true);
  // const [days, setDays] = useState(0);
  // const [hours, setHours] = useState(0);
  // const [minutes, setMinutes] = useState(0);
  // const [seconds, setSeconds] = useState(0);
  // const endTime = moment(starts_at).toDate().getTime();

  let interval: any = useRef();

  const pointsCoveredIcons = [
    'https://bigbangsocial.sgp1.cdn.digitaloceanspaces.com/images/scam-icon.svg',
    'https://bigbangsocial.sgp1.cdn.digitaloceanspaces.com/images/safety-icon.svg',
    'https://bigbangsocial.sgp1.cdn.digitaloceanspaces.com/images/social-icon.svg',
    'https://bigbangsocial.sgp1.cdn.digitaloceanspaces.com/images/reputation-icon.svg',
    'https://bigbangsocial.sgp1.cdn.digitaloceanspaces.com/images/guidelines-icon.svg',
    'https://bigbangsocial.sgp1.cdn.digitaloceanspaces.com/images/qna-icon.svg',
  ];

  // const startCoundown = () => {
  //   interval = setInterval(() => {
  //     const now = new Date().getTime();
  //     const timeLeft = (endTime - now) / 1000;
  //     setSeconds(Math.floor(timeLeft % 60));
  //     setMinutes(Math.floor(timeLeft / 60) % 60);
  //     setHours(Math.floor(timeLeft / (60 * 60)) % 24);
  //     setDays(Math.floor(timeLeft / (60 * 60 * 24)));
  //     // console.log('now', now);
  //     if (timeLeft <= 0) {
  //       clearInterval(interval.current);
  //       setSeconds(0);
  //       setMinutes(0);
  //       setHours(0);
  //       setDays(0);
  //       setShowCountdown(false);
  //     }
  //   }, 1000);
  // };

  useEffect(() => {
    // startCoundown();
    // return () => {
    //   clearInterval(interval.current);
    // };
  });

  return (
    <>
      <div className="blueV2-home-nav sticky top-0 z-1 black-bg w-full">
        <div className="max-width mx-auto nav-inline !font-space !px-2 flex justify-between">
          {/* <h2 className="!font-space">{host_name}</h2> */}
          <p className="opacity-0"></p>
          <div className="blueV2-countdowns hidden md:flex !font-space">
            <p className="text-white font-medium">
              Get a guide of <b>Digital safety</b> worth <b>₹250 FREE</b> by{' '}
              <b>ENROLLING NOW!</b>
            </p>
          </div>
          {/* <div className="blueV2-countdowns hidden md:flex !font-space">
            <h5 className="!font-space" id="days">
              {days}
            </h5>
            <h6 className="!font-space">Days</h6>
            <img className="dots" src={dots} />
            <h5 className="!font-space" id="hours">
              {hours}
            </h5>
            <h6 className="!font-space">Hours</h6>
            <img className="dots" src={dots} />
            <h5 className="!font-space" id="mins">
              {minutes}
            </h5>
            <h6 className="!font-space">Mins</h6>
            <img className="dots" src={dots} />
            <h5 className="!font-space" id="secs">
              {seconds}
            </h5>
            <h6 className="!font-space">Seconds</h6>
          </div> */}

          <button
            onClick={() => onRegister()}
            type="button"
            className="flex blueV2-desktop items-center justify-center text-white bg-[#005AF1] hover:bg-black focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm sm:px-5 px-10 py-2.5 mb-2"
          >
            <span className="text-[14px] md:mr-0 sm:mr-4">
              Register at{' '}
              <span className="line-through font-light">
                ₹{before_discount_amount}
              </span>{' '}
              ₹{registration_amount}
            </span>
          </button>
          <a
            className="bg-[#005AF1] blueV2-register-btn blueV2-mobile"
            onClick={() => onRegister()}
          >
            Register
          </a>
        </div>
        {/* <div className="blueV2-countdowns flex md:hidden !px-2">
          <h5 className="!font-space" id="dayss">
            {days}
          </h5>
          <h6 className="!font-space">Days</h6>
          <img className="dots" src={dots} />
          <h5 className="!font-space" id="hourss">
            {hours}
          </h5>
          <h6 className="!font-space">Hours</h6>
          <img className="dots" src={dots} />
          <h5 className="!font-space" id="minss">
            {minutes}
          </h5>
          <h6 className="!font-space">Mins</h6>
          <img className="dots" src={dots} />
          <h5 className="!font-space" id="secss">
            {seconds}
          </h5>
          <h6 className="!font-space">Sec</h6>
        </div> */}
        {/* <div className="blueV2-countdowns flex md:hidden !px-2">
          <p className="text-white font-medium">
            Get a guide of <b>Digital safety</b> worth <b>₹250 FREE</b> by{' '}
            <b>ENROLLING NOW!</b>
          </p>
        </div> */}
      </div>

      <div className="relative w-full ">
        <div className="blueV2-home lg:px-[150px] lg:py-[150px] w-full text-white p-4 !py-16 sm:px-[3rem] text-left relative lg:flex gap-16 BricolageFont z-[10]">
          <div className="max-w-[1200px] sm:grid sm:grid-cols-2 gap-4">
            <div className="w-full flex-col gap-2 gap-lg-4 self-center grid sm:text-left text-center">
              <h5 className="text-[20px] font-semibold mt-[16px] text-[#6B9AEA]">
                {moment(starts_at).format('ddd')}{' '}
                {moment(starts_at).format('ll')}
                <br />
                {moment(starts_at).format('hh:mm A')} -{' '}
                {moment(ends_at).format('hh:mm A')} IST
              </h5>
              <h3 className="text-[32px] font-bold mb-[24px] md:text-left text-center ">
                {name}
              </h3>
              {address && <p className="text-[16px] mb-4">📍 {address}</p>}
              <div className="sm:block md:hidden h-[200px]"></div>
              <p className="font-medium mb-4 hidden md:block">{excerpt}</p>
              <div
                className="sm:w-fit grid w-full my-[15px] lg:ml-0 hidden md:block"
                onClick={() => onRegister()}
              >
                <RegisterCTA
                  amount={registration_amount}
                  beforeDiscount={before_discount_amount}
                />
              </div>
            </div>
            {false ? (
              <div className="w-full hidden md:flex justify-center lg:justify-end items-center flex pt-8">
                {/* <div className="max-w-[28rem] rounded-xl p-8 bg-[#515485]">
              <figure className="relative  transition-all duration-300 filter"> */}
                <img
                  className="rounded-xl shadow h-[400px]"
                  src={
                    'https://bigbangsocial.sgp1.cdn.digitaloceanspaces.com/images/blue-v2-cyber-hero.jpeg'
                  }
                  alt=""
                />
                {/* </figure> */}
                {/* </div> */}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="absolute top-0 left-[50%] z-0 w-1/2 h-full hidden md:block">
          <img src={heroImage} alt="" className="h-full w-full object-cover" />
        </div>
        <div className="absolute top-[50%] z-0 w-full md:hidden h-[50%]">
          <img
            src={heroImage}
            alt=""
            className="h-full w-full object-top object-cover"
          />
        </div>
      </div>

      <div className="bg-[#0B0B2F] w-full py-12 flex flex-col justify-center items-center justify-center md:hidden">
        <p className="font-medium mb-4 md:hidden text-white">{excerpt}</p>
        <RegisterCTA
          amount={registration_amount}
          beforeDiscount={before_discount_amount}
        />
      </div>

      {/*About the workshop*/}
      <div className="lg:px-[150px] lg:py-[100px] flex w-full p-4 sm:px-[3rem] !py-16 gap-16 bg-[#0B0B2F] text-white BricolageFont centerPage">
        <div className="w-full">
          <div className="w-full  pb-[40px]">
            <div className="hidden sm:grid sm:grid-cols-4 grid-cols-2 gap-4">
              {statistics.map((stat, index) => (
                <div
                  className={`blueV2-creatorCard ${
                    index < statistics.length - 1
                      ? 'border-r border-[#444]'
                      : ''
                  }`}
                >
                  <h3 className="text-[24px] font-semibold">
                    {stat.value.value}
                  </h3>
                  <p className="">{stat.value.title}</p>
                </div>
              ))}
            </div>
            <div className="sm:hidden grid grid-cols-1 gap-4">
              <div className="grid grid-cols-2 gap-4">
                {statistics.map((stat, index) => (
                  <div
                    className={`blueV2-creatorCard ${
                      index < statistics.length ? 'border-b border-[#444]' : ''
                    }`}
                  >
                    <h3 className="text-[24px] font-semibold">
                      {stat.value.value}
                    </h3>
                    <p className="">{stat.value.title}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full gap-16 sm:flex">
            <div className="sm:w-1/2 w-full">
              <img
                className="md:flex hidden rounded-xl shadow w-[500px] object-cover"
                src={host_image_alt_url || host_image_url}
                alt=""
              />
            </div>
            <div className="sm:w-1/2 w-full">
              <h3 className="sm:text-[44px] text-[28px] pb-[28px] font-semibold leading-6 text-left">
                {about_workshop_title || 'About the Workshop'}
              </h3>
              <p className="text-left text-md text-[18px]">{about_workshop}</p>
              <div
                className="sm:w-fit grid w-full my-[15px] lg:ml-0"
                onClick={() => onRegister()}
              >
                <RegisterCTA
                  amount={registration_amount}
                  beforeDiscount={before_discount_amount}
                />
              </div>
            </div>
            <img
              className="flex md:hidden rounded-xl shadow w-[500px] object-cover mt-8"
              src={host_image_alt_url || host_image_url}
              alt=""
            />
          </div>
          <div className="grid md:hidden grid-cols-1 md:grid-cols-2 gap-4 mt-8">
            <div className="aboutCard gap-4 items-center p-4 border-1 bg-[#1C1C35]">
              <div className="flex flex-col">
                <p className="text-[18px] flex">
                  <img src={calendar} className="h-[20px] mb-4 mr-2" />{' '}
                  {isOnline ? 'Online' : 'Offline'} Workshop{' '}
                  {isOnline ? `(${address})` : ''}
                </p>
                <p className="text-[14px]">
                  {moment(ends_at).diff(moment(starts_at), 'hours')} Hours
                </p>
              </div>
            </div>
            <div className="aboutCard gap-4 items-center bg-[#1C1C35]">
              <div className="flex flex-col">
                <p className="text-[18px] flex">
                  <img src={calendar} className="h-[20px] mb-4 mr-2" />
                  {moment(starts_at).format('ddd, MMM DD, YYYY')}
                </p>
                <p className="text-[14px]">
                  {moment(starts_at).format('hh:mm A')} -{' '}
                  {moment(ends_at).format('hh:mm A')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Meet the Mentor section*/}
      <div className="lg:px-[150px] lg:py-[100px] w-full bg-[#0B0B2F] text-white sm:px-[3rem] p-4 !py-16 gap-16 BricolageFont centerPage">
        <div className="max-w-[1200px]">
          <h3 className="sm:hidden flex text-[28px]  pb-[24px] font-semibold leading-6 text-left">
            Meet the mentor
          </h3>
          <div className="w-full sm:flex gap-8">
            <div className="w-full sm:pt-0 pt-4">
              <h3 className="sm:flex hidden text-[44px] pb-[24px] font-semibold leading-6 text-left">
                Meet the mentor
              </h3>
              <div className="w-full justify-center flex gap-x-12 mb-4 flex-col md:flex-row overflow-x-auto">
                <div className="w-full md:w-1/3 mt-4">
                  <img
                    className="w-full rounded-lg aspect-square object-cover"
                    src={host_image_url}
                    alt="Sunset in the mountains"
                  />
                  <div className="px-2 py-4">
                    <div className="font-bold text-xl mb-2">{host_name}</div>
                    <p className="mt-4">{host_bio}</p>
                  </div>
                </div>
                {coHosts && coHosts.length ? (
                  coHosts.map((host) => (
                    <div className="w-full md:w-1/3 mt-4">
                      <img
                        className="w-full rounded-lg aspect-square object-cover"
                        src={host.image_url}
                        alt="Sunset in the mountains"
                      />
                      <div className="px-2 py-4">
                        <div className="font-bold text-xl mb-2">
                          {host.name}
                        </div>
                        <p className="mt-4">{host.bio}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {!isOnline && address && (
            <div className="grid grid-cols-1 gap-4 mb-3">
              <div className="aboutCard gap-4 items-center p-4 border-1 bg-[#1C1C35]">
                <div className="flex flex-col">
                  <p className="text-[18px] flex">
                    <img src={map} className="h-[20px] mb-4 mr-2" /> Address
                  </p>
                  <p className="text-[14px]">{address}</p>
                </div>
              </div>
            </div>
          )}
          <div className="grid hidden md:grid grid-cols-1 md:grid-cols-2 gap-4 ">
            <div className="aboutCard gap-4 items-center p-4 border-1 bg-[#1C1C35]">
              <div className="flex flex-col">
                <p className="text-[18px] flex">
                  <img src={calendar} className="h-[20px] mb-4 mr-2" />{' '}
                  {isOnline ? 'Online' : 'Offline'} Workshop{' '}
                  {isOnline ? `(${address})` : ''}
                </p>
                <p className="text-[14px]">
                  {moment(ends_at).diff(moment(starts_at), 'hours')} Hours
                </p>
              </div>
            </div>
            <div className="aboutCard gap-4 items-center bg-[#1C1C35]">
              <div className="flex flex-col">
                <p className="text-[18px] flex">
                  <img src={calendar} className="h-[20px] mb-4 mr-2" />
                  {moment(starts_at).format('ddd, MMM DD, YYYY')}
                </p>
                <p className="text-[14px]">
                  {moment(starts_at).format('hh:mm A')} -{' '}
                  {moment(ends_at).format('hh:mm A')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Point Covered section */}
      <div className="lg:px-[150px] lg:py-[100px] w-full text-white sm:px-[3rem] p-4 !py-16 bg-[#06061A] BricolageFont centerPage">
        <div className="max-w-[1200px] sm:items-center flex flex-col">
          <div className="pb-[28px] text-center">
            <h3 className="sm:text-[44px] text-[28px] font-bold tracking-tight">
              {points_covered_title || 'Why attend this workshop?'}
            </h3>
          </div>
          <div className="sm:grid sm:grid-cols-2 md:grid-cols-3 gap-4 text-left">
            {points_covered.map((it, i) => {
              return (
                <div className="sm:mb-0 mb-3 bg-[#1C1C35] rounded-lg border-1 border-[#515485]">
                  <div className="flex p-3 items-center gap-3">
                    <img
                      src={pointsCoveredIcons[i]}
                      alt=""
                      className="w-12 h-12 ml-2"
                    />
                    <div>
                      <h6 className="text-[16px] font-semibold tracking-tight ">
                        {it.value.title}
                      </h6>
                      {/* <p className="mt-1 font-thin">{it.value.body}</p> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="pt-[32px] text-center" onClick={() => onRegister()}>
            <div
              className="sm:w-fit grid w-full my-[15px] lg:ml-0"
              onClick={() => onRegister()}
            >
              <RegisterCTA
                amount={registration_amount}
                beforeDiscount={before_discount_amount}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Benefit Section */}
      {benefits.length > 0 && (
        <div className="lg:px-[150px] w-full text-white sm:px-[3rem] p-4 !py-16 bg-[#06061A] BricolageFont centerPage">
          <div className="w-full bg-[#0D1053] rounded-lg p-8">
            <div className="pb-[24px]">
              <h3 className="sm:text-[44px] text-[32px] font-semibold tracking-tight text-white">
                {benefits_title || 'What will you learn from this workshop?'}
              </h3>
            </div>
            <div className="sm:grid sm:grid-cols-2 gap-x-6 gap-y-6 text-left mt-6">
              {benefits.map((benefit) => (
                <div className="mb-[40px]">
                  <div className="font-semibold text-white text-[16px] pb-2">
                    {benefit.value.title}
                  </div>
                  <div className="font-thin text-sm text-white">
                    {benefit.value.body && `${benefit.value.body}`}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/*Testimonial section*/}
      {testimonials && testimonials.length > 0 ? (
        <div className="blueV2-testimonials lg:px-[150px] lg:py-[100px] w-full bg-[#06061A] text-white sm:px-[3rem] p-4 !py-16 gap-16 BricolageFont centerPage">
          <div className="w-full flex flex-col gap-8">
            <h3 className="flex text-[44px] justify-center pb-[24px] font-semibold leading-6 text-left">
              {testimonials_title || 'Testimonials'}
            </h3>
            <div className="w-full sm:flex flex flex-col gap-8">
              <div className="w-full cards tab-card">
                <div className="cards-div sm:flex-row flex-col gap-8">
                  {testimonials.map((testimonial) => (
                    <div className="card-div">
                      <h6>{testimonial.value.text}</h6>

                      <div className="card-footer">
                        {testimonial.value.image ? (
                          <img
                            src={testimonial.value.image}
                            alt=""
                            className="h-[43px] w-[43px] rounded-full object-cover"
                          />
                        ) : (
                          <></>
                        )}
                        <div className="card-foo">
                          <h4>{testimonial.value.author}</h4>
                          {/* <p>{testimonial.value.designation}</p> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/*FAQS section*/}
      {faqs && faqs.length > 0 ? (
        <div className="faq lg:px-[150px] lg:py-[100px] w-full bg-[#0B0B2F] text-white sm:px-[3rem] p-4 !py-16 gap-16 BricolageFont centerPage">
          <div className="w-full">
            <div className="flex flex-col gap-8">
              <div className="w-full flex justify-center">
                <h1 className="">FAQs</h1>
              </div>

              <div className="w-full">
                <div className="text-div !flex flex-col w-full">
                  {faqs.map((faq) => (
                    <FAQBox
                      title={faq.value.question}
                      content={faq.value.answer}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/*Timer section*/}
      {/* <div className="hurry w-full">
        <div className="max-width mx-auto px-4">
          <div className="head-div !flex flex-col md:!flex-row">
            <div>
              <h5 className="purple-text">MAKE HURRY</h5>
              <h1 className="!font-space">
                Time is running out, Book your spot fast
              </h1>
            </div>
          </div>
          <div className="counter hidden md:flex flex-row">
            <div className="time">
              <h4 id="Days">{days} Days</h4>
            </div>
            <div className="time">
              <h4 id="Hours">{hours} Hours</h4>
            </div>

            <div className="time">
              <h4 id="Mins">{minutes} Mins</h4>
            </div>
            <div className="time">
              <h4 id="Secs">{seconds} Seconds</h4>
            </div>
          </div>

          <div className="flex md:hidden counter d-flex d-sm-none ">
            <div className="time ">
              <h4 id="Dayss">{days}</h4>
              <h4 className="!text-[#a9a9a9]">Days</h4>
            </div>
            <div className="time ">
              <h4 id="Hourss">{hours}</h4>
              <h4 className="!text-[#a9a9a9]">Hours</h4>
            </div>
          </div>
          <div className="flex md:hidden counter d-flex d-sm-none ">
            <div className="time ">
              <h4 id="Minss">{minutes}</h4>
              <h4 className="!text-[#a9a9a9]">Mins</h4>
            </div>
            <div className="time ">
              <h4 id="Secss">{seconds}</h4>
              <h4 className="!text-[#a9a9a9]">Sec</h4>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

const RegisterCTA = ({
  amount,
  beforeDiscount,
}: {
  amount: number;
  beforeDiscount?: number;
}) => {
  return (
    <button
      type="button"
      className="flex items-center justify-center gap-2 text-white bg-[#005AF1] hover:bg-black focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm py-2.5 mb-2 px-8"
    >
      <span className="text-[18px]">
        Register at{' '}
        {beforeDiscount && (
          <>
            <span className="line-through font-light">₹{beforeDiscount}</span>{' '}
          </>
        )}
        ₹{amount}
      </span>
      <img src={arrowRight} alt="" />
    </button>
  );
};
