import React from 'react';
import { Link } from 'react-router-dom';

export const Success = () => {
  const orderId = window.localStorage.getItem('orderId');
  const email = window.localStorage.getItem('email');
  const slug = window.localStorage.getItem('slug');

  let subtext = `All the information you'd need will be shared a day before on your
    email and WhatsApp`;

  if (slug === 'red-flags-unite-1') {
    subtext =
      "All details will be shared on Rebel's community. Look out for the link to join the community in the confirmation email.";
  }

  return (
    <div className="lg:p-[150px] w-full min-h-screen text-black py-16 text-center">
      <div className="pb-[48px] pt-20">
        <h3 className="text-[32px] font-bold pb-[15px]">
          Thrilled that you've booked a spot!
        </h3>
        {orderId ? <p>Your order ID: {orderId}</p> : null}
        {email ? (
          <p>You will receive an email confirmation at {email} shortly.</p>
        ) : (
          <p>You will receive an email confirmation soon.</p>
        )}
        <p>{subtext}</p>
      </div>
    </div>
  );
};
